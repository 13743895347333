//imports
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../globals";
import { MiniModalControllerProps } from "./interfaces";

//view
import MiniModalView from "./miniModalView";

const MiniModalController = ({
    title,
    show,
    onClose,
    children
}: MiniModalControllerProps) => {

    //useSelector
    const idleLocked : boolean = useSelector((state : RootState) => state.idleLockSwitch.value);

    //useState
    const [isOpen, setIsOpen] = useState<boolean>(show);

    //useEffect
    useEffect(() => {
        setIsOpen(show);
    }, [show]);

    return (
        isOpen?
        <MiniModalView
            title = {title}
            isOpen = {isOpen}
            setIsOpen = {setIsOpen}
            onClose = {onClose}
            idleLocked = {idleLocked}
            children = {children}
        />
        :
        <></>
    )
}

export default MiniModalController