//imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ChatComponentViewProps, Chat } from "./interfaces";
import Constants from "../../constants";

//styles
import "./styles.scss";

const ChatComponentView = ({
    multiChat,
    chats,
    chatId,
    setChatId,
    input,
    textNote,
    changeIntput,
    handleChat,
    lecture
}: ChatComponentViewProps) => {


    return (
        <>
            {
                chats?.length?
                <div className="chatNoteContainer">
                    {
                        chats.map((chat: Chat, index) => 
                            <div
                                key={`${index}${chat.message}`}
                                className="chatNoteTile d-flex flex-row align-items-center">
                                <div className={`chatNote ${(chatId && chatId == chat.id)? 'chatNoteSelected' : '' }`}>
                                    <span className="text">
                                        {chat.message}
                                    </span>
                                    <div className="chatNoteUpdated">
                                        {
                                            chat.updatedBy?
                                            <span className="by">{chat.updatedBy}</span>
                                            :
                                            ""
                                        }
                                        {
                                            chat.updatedAt?
                                            <span className="at">{chat.updatedAt}</span>
                                            :
                                            ""
                                        }
                                    </div>
                                </div>
                                {
                                    !lecture?
                                    <button
                                        className="edit"
                                        type="button"
                                        onClick={()=>{ setChatId(chat.id) }}
                                        >
                                        <FontAwesomeIcon icon={solid("edit")} />
                                    </button>
                                    :
                                    ""
                                }
                            </div>
                        )
                    }
                </div>
                :
                ""
            }
            {
                !lecture?
                (
                    (!chats || chats.length < 1 || chatId || multiChat)?
                    <div className="editNote">
                        <span className="editNoteLabel">
                            {textNote}
                        </span>
                        <div className="editNoteContent">
                            <input
                                className="edit"
                                type="text"
                                onInput={(e) => {changeIntput(e.currentTarget.value)}}
                                value={input}
                            />
                            <button
                                className="send"
                                type="button"
                                onClick={handleChat}>
                                <FontAwesomeIcon icon={solid("paper-plane")} />
                            </button>
                        </div>
                    </div>
                    :
                    <span className="selectToEdit">{Constants.Common.selectToEdit}</span>
                )
                :
                ""
            }
        </>
    )
}

export default ChatComponentView