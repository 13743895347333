import axios
	from "axios"
import store
	from "./../../globals"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import {isNotEmpty, isEmpty, removeRecords, toAcceptableDate}
	from "./../../common"
import {scheduleStatus}
	from "./../../constants"

const API_URL      = process.env.REACT_APP_API_URL
const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus"

class SchedulesService
{
	sessionToken = store.getState().sessionToken.value
	campusId = store.getState().currentUserCampusId.value

	constructor()
	{
		store.subscribe
		(
			()=>
			{
				this.sessionToken = store.getState().sessionToken.value
				this.campusId = store.getState().currentUserCampusId.value
			}
		)
	}

	async searchSchedules(search? : any, page? : number)
	{
		let params       : any = []
		let courseIds    : any = []
		let levelIds     : any = []
		let buildingIds  : any = []
		let classroomIds : any = isEmpty(search.classroomIds) ? [] : search.classroomIds

		if(page)
		{
			params.push("page=" + page)
		}

		if(search)
		{
			if(isNotEmpty(search?.q))
			{
				params.push("q=" + search.q)
			}

			if(search.period && search.period.id)
			{
				params.push("periodId=" + search.period.id)
			}

			if(search.startValue)
			{
				params.push("start=" + toAcceptableDate(search.startValue))
				params.push("end="   + toAcceptableDate(search.endValue))
			}

			if(search.categoryId > 0)
			{
				params.push("categoryId=" + search.categoryId)
			}

			if(search.exceptionId != null)
			{
				params.push("exceptionId=" + search.exceptionId)
			}

			if(isNotEmpty(search.courses))
			{
				courseIds = search.courses.filter
				(
					(course : any)=>
					{
						let hasLevels : boolean = course.levels.length > 0

						if(hasLevels)
						{
							levelIds = [...levelIds, _.map(course.levels, "id")]
						}

						return !hasLevels
					}
				).map((course : any)=>(course.id))

				if(courseIds.length)
				{
					params.push("courseIds=" + courseIds.join(","))
				}

				if(levelIds.length)
				{
					params.push("levelIds=" + levelIds.join(","))
				}
			}

			if(isNotEmpty(search.buildings))
			{
				buildingIds = search.buildings.filter
				(
					(building : any)=>
					{
						let hasClassrooms : boolean = building.classrooms.length > 0

						if(hasClassrooms)
						{
							classroomIds = [...classroomIds, _.map(building.classrooms, "id")]
						}

						return !hasClassrooms
					}
				).map((building : any)=>(building.id))

				if(buildingIds.length > 0)
				{
					params.push("buildingIds=" + buildingIds.join(","))
				}

				if(classroomIds.length > 0)
				{
					params.push("classroomIds=" + classroomIds.join(","))
				}
			}

			if(classroomIds.length)
			{
				params.push("classroomIds=" + classroomIds.join(","))
			}

			if(isNotEmpty(search.mentorRef))
			{
				params.push("mentorIds=" + _.map(Object.values(search.mentorRef), "id").join(","))
			}

			if(isNotEmpty(search.tags))
			{
				params.push("tagIds=" + _.map(search.tags, "id").join(","))
			}

			if(search.activeAndOver)
			{
				params.push("activeAndOver")
			}
			else
			{
				if(search.statusId >= 0)
				{
					params.push("statusId=" + search.statusId)
				}
			}
		}

		return axios.get
		(
			RESOURCE_URL + "/" + this.campusId + "/schedules" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	saveSchedule(selectedSchedule : any, editedSchedule : any, hourList : any)
	{
		const saveFunction = editedSchedule.id == null ? this.registerSchedule : this.updateSchedule

		let record : any=
		{
			//type_id : schedule.type_id,------------------------------------------------------------------------------------
			name                        : editedSchedule.name,
			notes                       : editedSchedule.notes,
			subscriptionPrice           : editedSchedule.subscription_price,
			monthlyBillingPrice         : editedSchedule.monthly_billing_price,
			subscriptionSurchargeCost   : editedSchedule.subscription_surcharge_cost,
			monthlyBillingSurchargeCost : editedSchedule.monthly_billing_surcharge_cost,
			secondSurchargeCost         : editedSchedule.second_surcharge_cost,
			mentorFee                   : editedSchedule.mentor_fee,
			firstSurchargeMonthDay      : editedSchedule.first_surcharge_month_day,
			secondSurchargeMonthDay     : editedSchedule.second_surcharge_month_day,
			billableMonths              : editedSchedule.billable_months,
			studentsLimit               : editedSchedule.students_limit,
			studentsMinimum             : editedSchedule.students_minimum,
			periodId                    : editedSchedule.period.id,
			startDate                   : toAcceptableDate(editedSchedule.startValue),
			endDate                     : toAcceptableDate(editedSchedule.endValue),
			levelId                     : editedSchedule.level_id,
			statusId                    : editedSchedule.status_id,
			classroomId                 : editedSchedule.classroom.id,
			//Takes only the week day and hour part (removes the 'DH' prefix)------------------------------------------------
			hours                       : hourList.map
			(
				(hour : number)=>
				{
					return sprintf("%04d", hour)
				}
			)
			.join(",") + ""
		}

		if((editedSchedule.status_id != scheduleStatus.ACTIVE) && isNotEmpty(editedSchedule.status_update_motive))
		{
			record.statusUpdateMotive = editedSchedule.status_update_motive
		}

		if(isNotEmpty(editedSchedule.notes))
		{
			record.notes = editedSchedule.notes
		}

		if(editedSchedule.mentor && editedSchedule.mentor.id)
		{
			record.mentorId = editedSchedule.mentor.id
		}

		return saveFunction
		(
			selectedSchedule.course_id,
			selectedSchedule.level_id,
			record,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			selectedSchedule.id
		)
	}

	async getLectures(campusId : number)
	{
		return axios.get
		(
			RESOURCE_URL + "/" + campusId + "/schedules/_lectures",
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + store.getState().sessionToken.value
				}
			}
		)
		.then
		(
			response=>
			{
				return response
			}
		)
	}

	async registerSchedule(courseId : number, levelId : number, registry : any, options : any)
	{
		return axios.post
			([RESOURCE_URL, store.getState().currentUserCampusId.value, "courses", courseId, "levels", levelId, "schedules"].join("/"), registry, options)
	}

	async updateSchedule(courseId : number, levelId : number, registry : any, options : any, id : number)
	{
		return axios.put
			([RESOURCE_URL, store.getState().currentUserCampusId.value, "courses", courseId, "levels", levelId, "schedules", id].join("/"), registry, options)
	}

	async removeSchedules(campusId : number, recordIds : any)
	{
		return removeRecords( RESOURCE_URL + "/" + campusId + "/schedules", recordIds)
	}
}

export default new SchedulesService()