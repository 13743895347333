import React, { useEffect, useState } from "react";
import { styles } from "./style";
import "./styles.css";

export interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
    pages: any;
    pageSizes: number[];
    onPageSizeChange: (size: number) => void;
    itemCount: any,
    pageSizeVisible?: boolean
}

const PaginationComponent: React.FC<PaginationProps> = ({
    pages,
    pageSizes,
    onPageSizeChange,
    itemCount,
    pageSizeVisible,
    style
}) => {
    const [activePage, setActivePage] = useState<number>(1);
    const [pageSize, setPageSize] = useState(pageSizes[0] ?? 10);

    const handlePageClick = (index: number, link: any) => {
        if (link.url) {
            setActivePage(index);
            link.onClick();
        }
    }

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newSize = parseInt(event.target.value);
        setPageSize(newSize);
        onPageSizeChange(newSize);
    }

    useEffect(() => {
        const newActivePage = pages.findIndex((page: any) => page.active);
        setActivePage(newActivePage);
    }, [pages, pageSize, itemCount]);

    return (
        <div className="styles_container" style={{...styles.container, ...style}}>
            <div style={styles.pageContainer}>
                <label style={{
                    position: "inherit"
                }}>
                    <span className="d-md-inline d-none">por página:</span>
                </label>
                <select
                    className="select_pagination"
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    style={styles.pageSizeSelect}
                >
                    {pageSizes.map(size => (
                        <option
                            key={size}
                            value={size}
                            style={styles.sizeOption}>
                            {size}
                        </option>
                    ))}
                </select>
            </div>
            <nav style={{
                ...styles.pageNavigationContainer,
                flexDirection: "column"
            }}>
                <label style={{
                    position: "inherit"
                }}>
                    {itemCount.start} a {itemCount.end} de {itemCount.total}
                </label>
                {pages && pages.length > 0 ?
                    <ul style={styles.pageItem}>
                        {pages.map((link: any, index: number) => (
                            <li key={index}
                                className={`d-md-inline-block page-item ${index === activePage ? "active" : ""} ${(index > 3 && index < pages.length - 2) ? "d-none" : ""}`}>
                                <button type="button"
                                    className={`btn btn-default ${link.url ? "active" : "disabled"} button_pagination`}
                                    disabled={!link.url}
                                    onClick={() => handlePageClick(index, link)}
                                    aria-label={link.label.replace("Previous", "").replace("Next", "")}
                                    style={{
                                        opacity: index === activePage ? 1 : 0.8,
                                        color: index === activePage ? "red" : ""
                                    }}>
                                    <span aria-hidden="true">
                                        {
                                            link.label.replace("Previous", "")
                                                .replace("Next", "")
                                                .replace("&laquo;", "«")
                                                .replace("&raquo;", "»")
                                        }
                                    </span>
                                </button>
                            </li>
                        ))}
                    </ul>
                    : <React.Fragment />
                }
            </nav>
        </div>
    );
};

export default PaginationComponent;