//imports
import { useEffect, useState } from "react";
import { ChatComponentControllerProps, Chat } from "./interfaces";
import Constants from "../../constants";

//view
import ChatComponentView from "./chatComponentView";

const ChatComponentController = ({
    multiChat,
    chats: initialChats,
    onChange,
    lecture
}: ChatComponentControllerProps) => {


    const [chats, setChats] = useState<Chat[] | []>(initialChats);
    const [input, setInput] = useState<string>('');
    const [chatId, setChatId] = useState<number | string | null>(null);
    const [count, setCount] = useState<number>(1);
    const [textNote, setTextNote] = useState<string>(Constants.Common.add);

    useEffect(() => {
        if (chats.length > 0) {
            onChange(chats);
        }
    }, [chats])

    useEffect(() => {
        if (chatId) {
            setTextNote(Constants.Common.edit);
        }else{
            setTextNote(Constants.Common.add);
        }
    }, [chatId])

    const changeIntput = (newInput : string) => {
        setInput(newInput);
    }

    const handleChat = () => {

        if (chatId) {
            let newChats: Chat[] = [];
            chats.map((chat) => {
                if (chat.id == chatId) {
                    let newChat = {
                        ...chat,
                        message: input
                    }
                    newChats.push(newChat);
                }else{
                    newChats.push(chat);
                }
            });
            setChats(newChats);
            setChatId(null);
        } else {
            const d = new Date();
            let time = d.getTime();
            let newChat = [
                {
                    message: input,
                    id: `N-${count}-${time}`
                }
            ]
            setCount(count + 1)
            let newChats = multiChat? [...chats, ...newChat] : newChat;
            setChats(newChats);
        }
        setInput('');
    }

    return (
        <ChatComponentView
            multiChat={multiChat} 
            chats={chats}
            chatId={chatId}
            setChatId={setChatId}
            input={input}
            textNote={textNote}
            changeIntput={changeIntput}
            handleChat={handleChat}
            lecture={lecture}
        />
    )
}

export default ChatComponentController