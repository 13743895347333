import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { sprintf } from "sprintf-js";
import UnderlineHeading from "../../../underlineHeading";
import Constants,
{
    paymentStatus,
    PERMISSIONS_CODES
} from "../../../../constants";


import {
    AppDispatch,
    disableIdleLockSwitch,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../../globals";
import FileUploadComponent from "../../../fileUpload/fileUpload";
import { useForm } from "react-hook-form";
import { getErrorMessage } from "../../../../common";
import HttpManager from "../../../../services/HttpManager";
import { styles } from "./styles";


export interface PaymentDetailProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * The student data to show.
     */
    student: any;
    /**
     * Payment data to show.
     */
    payment: any;
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
    /**
     * 
     * @function to Handle success payment
     */
    onSuccess: () => void
}

const PaymentUpdateModalComponent: React.FC<PaymentDetailProps> = ({
    student,
    payment,
    closeModal,
    onSuccess
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const http = HttpManager.getInstance();
    const permissions: any[] = useSelector((state: RootState) => state.rootReducer.sessionState.permissions);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const currencies: any = useSelector((state: RootState) => state.rootReducer.catalog.currencies || []);
    const [currency, setCurrency] = useState<any>({});
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm();

    const allowedFiletypes: any[] = [
        { file: "image/png", type: "png" },
        { file: "image/jpeg", type: "jpeg" },
        { file: "application/pdf", type: "pdf" }
    ];

    useEffect(() => {
        register("attachedFiles", {
            required: true,
        });
    }, [register]);

    const handleFileChange = (files: File[]) => {
        setValue("attachedFiles", files);
    }

    const addPaymentDocument = async (data: any) => {
        try {
            dispatch(enableIdleLockSwitch());
            const payload = {
                ...data
            };
            const response = await http.paymentService.addPaymentDocument(
                currentCampus.id,
                payment.id,
                payload
            )
            reset();
            onSuccess();
        } catch (error) {
            console.error("Error submitting form:", error);
            onError(getErrorMessage(error));
        } finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                Error
            </h3>
        }))
    }

    useEffect(() => {
        const newCurrency = currencies.find((currency: any) => currency.id === payment.currencyId) || {};
        setCurrency(newCurrency);
    }, [currencies]);

    return (
        student &&
        <React.Fragment>
            <div style={{
                display: "flex",
                alignItems: "stretch"
            }}>
                <h2 style={{
                    flexGrow: 1
                }}>
                    <span style={{
                        paddingLeft: "10px"
                    }}>
                        {Constants.Common.payment} {payment.id}
                    </span>
                </h2>
                <button style={{
                    ...styles.btnCloseModal,
                    flexGrow: 0,
                    zIndex: 2
                }}
                    onClick={closeModal}
                >
                    <FontAwesomeIcon
                        icon={solid("times")}
                        style={{
                            height: "100%",
                            position: "relative",
                            bottom: "1px"
                        }}
                    />
                </button>
            </div>
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                gap: "10px",
                padding: "10px"
            }}>
                <div
                    style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <label htmlFor="name"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.student}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {student.firstName}
                        &nbsp;
                        {student.lastName}
                    </div>
                </div>
            </div>

            <div style={{
                background: "#F1F1FF",
                padding: "15px"
            }}>
                <h5 style={{
                    display: "inline-block",
                    width: "70%",
                    textAlign: "center"
                }}>
                    {
                        `${Constants.Common.paymentReceive} (${currency.code}) `
                    }
                </h5>
                <div style={{
                    display: "inline-block",
                    width: "30%",
                    textAlign: "right",
                    borderBottom: "1px solid #7678BF"
                }}>
                    <h5 style={{
                        marginBottom: "unset"
                    }}>
                        {sprintf("%.02f", payment.amount)}
                    </h5>
                </div>
            </div>

            <div style={{
                display: "flex",
                padding: "10px",
                gap: "10px"
            }}>
                <div style={{ width: "50%" }}>
                    <label htmlFor="date"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Screens.Finances.modalPayments.payment.date}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {payment.paymentDate}
                    </div>
                </div>
                <div style={{
                    width: "50%"
                }}>
                    <label htmlFor="status"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.status}
                    </label>
                    <div className="roView"
                        style={{
                            color: payment.statusId === paymentStatus.CANCELLED
                                ? Constants.Styles.text.colors.redLine
                                : Constants.Styles.text.colors.blackPrimary,
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {payment.status}
                    </div>
                </div>
            </div>

            <div style={{
                display: "flex",
                padding: "10px",
                gap: "10px"
            }}>
                <div style={{
                    width: "50%"
                }}>
                    <label htmlFor="date"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Screens.Finances.modalPayments.payment.method}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {payment.paymentMethod}
                    </div>
                </div>
                <div style={{
                    width: "50%"
                }}>
                    <label htmlFor="status"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Screens.Finances.modalPayments.payment.depositTo}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {payment.account}
                    </div>
                </div>
            </div>

            <div style={{
                display: "flex",
                padding: "10px",
                marginBottom: "20px"
            }}>
                <div style={{
                    width: "100%"
                }}>
                    <label htmlFor="date"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.notes}
                    </label>
                    <p style={{
                        padding: "5px",
                        margin: "0",
                        wordBreak: "break-word",
                        borderBottom: "1px solid #7678BF"
                    }}>
                        {payment.notes}
                    </p>
                </div>
            </div>
            <UnderlineHeading
                name={Constants.Common.attachments} />
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px"
            }}>
                {
                    permissions.includes(PERMISSIONS_CODES.PAYMENT_PERMISSIONS.UPDATE)
                        ?
                        <div style={{
                            paddingRight: "10px",
                            display: "flex",
                            flexDirection: "column",
                            width: "50%"
                        }}>
                            <form onSubmit={handleSubmit(addPaymentDocument)}
                                className="form">
                                <FileUploadComponent
                                    allowedFiletypes={allowedFiletypes}
                                    onFilesChange={handleFileChange}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={idleLocked}
                                    style={{
                                        width: "100%",
                                        marginTop: "25px"
                                    }}>
                                    {Constants.Common.saveChanges}
                                </button>
                                {/* <div>
                                    {errors.attachedFiles &&
                                        <p style={{
                                            ...styles.errorMessage
                                        }}>
                                            {errors.attachedFiles.message}
                                        </p>
                                    }
                                </div> */}
                            </form>
                        </div>
                        : <React.Fragment />
                }
            </div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px"
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <button className="btn btn-outline-default rounded-pill"
                        onClick={closeModal}
                        disabled={idleLocked}
                        type="button"
                        style={{
                            width: "50%",
                            border: "1px solid #000000"
                        }}
                    >
                        {Constants.Common.close}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );

}

export default PaymentUpdateModalComponent;