import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	useCallback,
	FormEvent
}
	from "react"
import {useNavigate}
	from "react-router-dom"
import {Badge}
	from "react-bootstrap"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid, regular}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {Popover}
	from "react-tiny-popover"
import _debounce
	from "lodash/debounce"
import {useDispatch, useSelector}
	from "react-redux"
import Multiselect
	from "multiselect-react-dropdown"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,
	enableIdleLockSwitch,
	disableIdleLockSwitch
}
	from "./../../../globals"
import Utils
	from "../../../utils/utils"
import UnderlineHeading
	from "./../../../components/underlineHeading"
import {DataDisplayModal}
	from "./../../../components/sectionContainer"
import
{
	UIState,
	weekDays,
	dayHours,
	revertStringDate,
	isNotEmpty,
	isEmpty
}
	from "./../../../common"

import Constants,
{
	ENROLLMENT_STATUS,
	ROLE,
	scheduleStatus,
	studentStatus,
	tunning
}
	from "./../../../constants"
import StudentsService
	from "./../../../services/campus/students.service"
import EnrollmentsService
	from "./../../../services/campus/enrollments.service"
import courseImage
	from "./../../../assets/images/course.png"
import userImage
	from "./../../../assets/images/user.png"

import "./../../../components/dropdown/style.css"
import "./../style.css"
import { setStudentData } from "./../../../redux/reducers/accountStatementReducer"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import
{
	setCourse,
	setTuitions
}
	from "../../../redux/reducers/scheduleReducer"
import ModalComponent
	from "./../../../components/modals/modal/modal"
import UpdateStudentEnrollmentComponent
	from "./../enrollments/updateStudentEnrollment"

type State = {isButtonDisabled : boolean}
const initialState : State = {isButtonDisabled : true}

type Action =
{
	type : "setIsButtonDisabled",
	payload : boolean
}

const reducer = (state: State, action: Action) : State=>
{
	switch (action.type) { case "setIsButtonDisabled": return { ...state, isButtonDisabled: action.payload } }
}

const EnrollmentPrompt = (props: any)=>
{
	const LEVEL_MATCHING    = 0
	const SCHEDULE_MATCHING = 1

	const enrollStatus: any =
	[
		solid("circle-plus"),
		solid("circle-check")
	]

	const enrollView =
	[
		"Información",
		"Inscritos"
	]

	const studentUI =
	[
		"btn btn-default",
		"btn btn-primary"
	]

	const [state, trigger] = useReducer(reducer, initialState)
	const dispatch : any = useDispatch()
	const navigate : any = useNavigate()
	const [studentsToEnroll, setStudentsToEnroll] = useState<any>([])
	const [edittingList, toggleEdittingList] = useState<boolean>(false)
	const [enrollmentCheck, setEnrollmentCheck] = useState<any>({})
	const [enrollmentDeletion, setEnrollmentDeletion] = useState<any>({})
	const [enrollmentAdding, setEnrollmentAdding] = useState<any>({})
	const [enrollmentInit, setEnrollmentInit] = useState<any>({})
	const [financeTrammelRef, setFinanceTrammelRef] = useState<any>({})
	const [matchTrammelRef, setMatchTrammelRef] = useState<any>({})
	const [aSearchInProgress, setASearchInProgress] = useState<boolean>(false)
	const userProfile: any = useSelector((state: RootState) => state.userProfile.value)
	const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value)
	const sessionToken: string | null = useSelector((state: RootState) => state.sessionToken.value)
	const themeToggle: boolean = useSelector((state: RootState) => state.themeToggle.value)
	const currentUserCampus: any = useSelector((state: RootState) => state.currentUserCampus.value)
	const basicInfo : any = useSelector((state : RootState) => state.basicInfo.value)
	const campusRef : any = useSelector((state: RootState) => state.campusRef.value)
	const [UIStatus, setUIStatus] = useState<number>(UIState.NORMAL)
	const [selectableRecords, setSelectableRecords] = useState<number>(0)
	const [listSearch, setListSearch] = useState<any>("")
	const [unchangedRecord, setUnchangedRecord] = useState<boolean>(false)
	const [nonActiveEnrollmentsFound, setNonActiveEnrollmentsFound] = useState<boolean>(false)
	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
	const [editableEnrollment, setEditableEnrollment] = useState<any>(null)
	const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
	const [saveError, setSaveError] = useState<any>(null)
	const [kitRef, setKitRef] = useState<any>(null)
	let   nameRef = useRef<HTMLInputElement>(null)
	let   recordFormRef = useRef<HTMLFormElement>(null)
	const [showUpdateStudentEnrollment, setShowUpdateStudentEnrollment] = useState<boolean>(false);
	const [student, setStudent] = useState<any>(null);
	const [enrollmentId, setEnrollmentId] = useState<number>(0);

	const navigateTo = (params: any) => {
		const studentData =
		{
			id: params.id,
			email: params.email,
			userId: params.id,
			firstName: params.firstName,
			lastName: params.lastName,
			identityCode: params.identityCode,
			identificationCode: params.identificationCode
		}
		dispatch(setStudentData(studentData));
		navigate("/statement", { state: params });
	}

	const updateStudentEnrollment = (enrollment: any) => {
		const studentData =
		{
			id : enrollment.id,
			email : enrollment.email,
			userId : enrollment.enrollmentId,
			firstName : enrollment.firstName,
			lastName : enrollment.lastName,
			identityCode : enrollment.identityCode,
			identificationCode : enrollment.identificationCode
		}
		setEnrollmentId(enrollment.enrollmentId);
		setStudent(studentData);
		setShowUpdateStudentEnrollment(true);
	}

	const handleCloseModal = () => {
		dispatch(setCourse(null));
		dispatch(setTuitions([]));
		setShowUpdateStudentEnrollment(false);
	}

	const hideEnrollment = () => {
		if (editableEnrollment) {
			setEditableEnrollment(null)
		}

		props.closeFn()
	}

	const unedit = () => {
		setEditableEnrollment(null)
	}

	const enrollStudent = (student: any) => {
		let formattedStudent: any = { ...student, editableKits: [] }

		if (enrollmentInit[formattedStudent.refIndex] == null) {
			setEditableEnrollment({ ...editableEnrollment, enrollments: [...editableEnrollment.enrollments, formattedStudent] })

			let adding = enrollmentAdding
			adding[formattedStudent.refIndex] = formattedStudent

			setEnrollmentAdding(adding)
		}
		else {
			let deletion = enrollmentDeletion

			delete deletion[formattedStudent.refIndex]

			setEnrollmentDeletion(deletion)

			setEditableEnrollment
				({
					...editableEnrollment, enrollments: editableEnrollment.enrollments.map
						(
							(enrolled: any) =>
							({
								...enrolled, deletion: enrolled.refIndex == formattedStudent.refIndex ? false : formattedStudent.refIndex
							})
						)
				})
		}
	}

	const studentsEnrollment = (students: any)=>
	{
		setStudentsToEnroll
		(
			students.map
			(
				(student: any)=>
				{
					if(student.id != userProfile.id && student.id != props.selectedEnrollment.mentor_id)
					{
						let record : any = {...student, refIndex : "S_" + student.id}
						//let sessionScheduleStart : any
						//let sessionScheduleEnd : any
						record.enrollments.filter((enrollment : any) => (enrollment.status_id == ENROLLMENT_STATUS.ACTIVE && enrollment.schedule.status_id == scheduleStatus.ACTIVE)).map
						(						
							(enrollment : any) =>
							{
								record.matchingLevel = enrollment.schedule.level_id == props.selectedEnrollment.level_id
								record.matchingSessions = false

								if(record.matchingLevel)
								{
									return
								}
								else
								{
									//sessionScheduleStart = new Date(enrollment.schedule.start_date + " 00:00:00")
									//sessionScheduleEnd = new Date(enrollment.schedule.end_date + " 00:00:00")

									if
									(
										(enrollment.schedule.start_date <= props.selectedEnrollment.end_date && enrollment.schedule.end_date >= props.selectedEnrollment.start_date) ||
										(
											(enrollment.schedule.start_date > props.selectedEnrollment.start_date && enrollment.schedule.start_date < props.selectedEnrollment.end_date) ||
											(enrollment.schedule.end_date > props.selectedEnrollment.start_date && enrollment.schedule.end_date < props.selectedEnrollment.end_date)
										)
									
										/*(
												Math.max.apply(null, [sessionScheduleEnd, props.selectedEnrollment.endDate]) -
												Math.min.apply(null, [sessionScheduleStart, props.selectedEnrollment.startDate])
										)
											<=
										(
											(props.selectedEnrollment.endDate - props.selectedEnrollment.startDate) +
												(sessionScheduleEnd - sessionScheduleStart)
										)*/
									)
									{
										enrollment.schedule.sessions.map
										(
											(session : any)=>
											{
												let currentHours = session.start_hours

												while(currentHours < session.end_hours)
												{
													if
													(
														props.selectedEnrollment.hours[sprintf("DH%d%02d", session.week_day, currentHours)]
													)
													{
														record.matchingSessions = true

														break
													}

													currentHours++
												}
											}
										)
									}

									if(record.matchingSessions)
									{
										return
									}
								}
							}
						)

								return record
							}
						}
					)
			)
	}

	const editEnrollment = async()=>
	{
		let enrollInit : any = {}
		let editableRecords : number = 0

		props.selectedEnrollment.enrollments.map
		(
			(enrollment: any)=>
			{
				enrollInit[enrollment.refIndex]=
				{
					...enrollment, endDate : new Date(props.selectedEnrollment.end_date + " 00:00:00"),
					startDate : new Date(props.selectedEnrollment.start_date + " 00:00:00")
				}

					if ((enrollment.tuitionInvoiceIds.length + enrollment.invoicedKits.length) < 1) {
						editableRecords++
					}
				}
			)

		dispatch(enableIdleLockSwitch())
		setUIStatus(UIState.LOCKED)

		try {
			let result: any = await EnrollmentsService.searchEnrollableStudents(props.selectedEnrollment.id, "", 100)

			if (result.status == 200 || result.status == 204) {
				studentsEnrollment(result.data)
				setListSearch("")
				setEnrollmentCheck({})
				setEnrollmentDeletion({})
				setEnrollmentAdding({})
				setEnrollmentInit(enrollInit)
				setSelectableRecords(editableRecords)
				setEditableEnrollment(props.selectedEnrollment)
				toggleEdittingList(true)
				setUIStatus(UIState.NORMAL)
			}
			else {
				setSaveError("Se produjo un error al realizar la búsqueda")
				setUIStatus(UIState.ERROR)
			}

			dispatch(disableIdleLockSwitch())
		}
		catch(error)
		{
			setSaveError("Se produjo un error al realizar la búsqueda")
			setUIStatus(UIState.ERROR)
			console.log(error)
			dispatch(disableIdleLockSwitch())
		}
	}

	const debounceFn: any = useCallback(_debounce(handleDebounceFn, 1000), [])

	async function handleDebounceFn(enrollmentId: number, term: string) {
		setASearchInProgress(true)

		try {
			setSaveError(null)
			setUIStatus(UIState.NORMAL)

			let result: any = await EnrollmentsService.searchEnrollableStudents(enrollmentId, term, 100)

			if (result.status == 200 || result.status == 204) {
				studentsEnrollment(result.data)
			}
			else {
				setSaveError("Se produjo un error al realizar la búsqueda")
				setUIStatus(UIState.ERROR)
			}
		}
		catch (error)
		{
			setSaveError("Se produjo un error al realizar la búsqueda")
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally {
			setASearchInProgress(false)
		}
	}


	const searchStudents = (term: string) => {
		setListSearch(term)
		debounceFn(editableEnrollment.id, term)
	}

	const saveEnrollment = async ()=>
	{
		let studentUpdateRefs: any = []
		let studentAddingRefs: any = []
		let allKits: any
		let extraKits: any
		let joinedKits: any

		editableEnrollment.enrollments.map
		(
			(item : any)=>
			{
				if(item && (enrollmentInit[item.refIndex] && enrollmentAdding[item.refIndex] == null))
				{
					allKits = []
					extraKits = item.extraKits.length < 1 ? [] : item.extraKits.filter((kit: any) => kit.toBeDeleted != true)
					joinedKits = [item.invoicedKits, extraKits, item.editableKits]
					joinedKits.map
					(
						(dataSet : any)=>
						{
							if(dataSet.length > 0)
							{
								allKits = [...allKits, ..._.map(dataSet, "id")]
							}
						}
					)
					studentUpdateRefs.push(item.id + (allKits.length < 1 ? "" : "," + allKits.join(",")))
				}
			}
		)

		editableEnrollment.enrollments.map
		(
			(item : any)=>
			{
				if(item && (enrollmentAdding[item.refIndex]))
				{
					studentAddingRefs.push(item.id + (item.editableKits.length < 1 ? "" : "," + _.map(item.editableKits, "id").join(",")))
				}
			}
		)

		if(saveError != null)
		{
			setSaveError(null)
		}

		if(studentUpdateRefs.length < 1 && studentAddingRefs.length < 1)
		{
			props.closeFn()
			setEditableEnrollment(null)
			setSaveSuccess(false)
			setUIStatus(UIState.NORMAL)
			props.afterCloseFn()
		}
		else
		{
			const saveErrorNotice : string = "La información no pudo ser guardada"
			setUIStatus(UIState.LOCKED)
			dispatch(enableIdleLockSwitch())
			try
			{
				let successfulDismissal: boolean = true
				if(studentUpdateRefs.length > 0)
				{
					const result = await EnrollmentsService.updateEnrollments(editableEnrollment, { studentWithKitIds: studentUpdateRefs.join(";") })

					if(result.status != 200 && result.status != 204)
					{
						successfulDismissal = false
					}
				}
				if(successfulDismissal && studentAddingRefs.length > 0)
				{
					const result = await EnrollmentsService.addEnrollments(editableEnrollment, ({ studentWithKitIds: studentAddingRefs.join(";") }))

					if(result.status != 200 && result.status != 204)
					{
						successfulDismissal = false
					}
				}
				if (!successfulDismissal)
				{
					setSaveError(saveErrorNotice)
					setUIStatus(UIState.ERROR)
				}
				else
				{
					setSaveSuccess(true)
					setUIStatus(UIState.SUCCESS)

					setTimeout
					(
						()=>
						{
							props.closeFn()
							setEditableEnrollment(null)
							setSaveSuccess(false)
							setUIStatus(UIState.NORMAL)
							props.afterCloseFn()
						},
						tunning.MODAL_DISMISS_DELAY
					)
				}
			}
			catch(error)
			{
				console.log(error)
				setSaveError(saveErrorNotice)
				setUIStatus(UIState.ERROR)
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
			}
		}
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		() =>
		{
			trigger
			({
				type : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect
	(
		() => {
			let agenda: any = {}
			let kitIndex: any = {}

		props
			.selectedEnrollment
			.level
			.kits
			.map((kit: any) => kitIndex["K_" + kit.id] = kit)

			setKitRef(kitIndex)
			toggleEdittingList(false)
			setEnrollmentInit({})
			setSelectableRecords(0)
		}, []
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const financeTrammelPrompt = (student: any) =>
	{
		setFinanceTrammelRef({ ...financeTrammelRef, ["S_" + student.id]: true })
		setTimeout
		(
			()=>
			{
				let financeTrammelMap = { ...financeTrammelRef }
				delete financeTrammelMap["S_" + student.id]
				setFinanceTrammelRef({ ...financeTrammelMap })
			},
			3000
		)
	}

	const matchTrammelPrompt = (student: any) =>
	{
		setMatchTrammelRef({ ...matchTrammelRef, ["S_" + student.id]: true })
		setTimeout
		(
			()=>
			{
				let matchTrammelMap = { ...matchTrammelRef }
				delete matchTrammelMap["S_" + student.id]
				setMatchTrammelRef({ ...matchTrammelMap })
			},
			3000
		)
	}

	const listRemoval = (target?: any) =>
	{
		let deletion : any = enrollmentDeletion
		let adding : any = enrollmentAdding
		let check : any = enrollmentCheck
		if(isNaN(target))
		{
			setEditableEnrollment
			({
				...editableEnrollment, enrollments: editableEnrollment.enrollments.filter
				(
					(student: any) => {
						if (check[student.refIndex]) {
							if (adding[student.refIndex]) {
								delete adding[student.refIndex]
							}
						}

						return student.stocked || (adding[student.refIndex] && !check[student.refIndex])
					}
				).map
				(
					(enrollment: any) => {
						if (enrollment.stocked && check[enrollment.refIndex]) {
							deletion[enrollment.refIndex] = true
						}

						return { ...enrollment, deletion: check[enrollment.refIndex] == true }
					}
				)
			})
			setEnrollmentAdding(adding)
			setEnrollmentDeletion(deletion)
			setEnrollmentCheck({})
		}
		else
		{
			let refIndex = editableEnrollment.enrollments[target].refIndex
			if(check[refIndex])
			{
				delete check[refIndex]
				setEnrollmentCheck(check)
			}
			if (adding[refIndex])
			{
				delete adding[refIndex]
				setEnrollmentAdding(adding)
			}
			else
			{
				deletion[refIndex] = true
				setEnrollmentDeletion(deletion)
			}
			if(editableEnrollment.enrollments[target].stocked)
			{
				setEditableEnrollment
				({
					...editableEnrollment, enrollments : editableEnrollment.enrollments.map
					(
						(enrollment: any, index: number) =>
						({
							...enrollment, deletion: index == target ? true : enrollment.deletion
						})
					)
				})
			}
			else
			{
				setEditableEnrollment
				({
					...editableEnrollment, enrollments: editableEnrollment.enrollments.filter((enrollment: any, index: number) => index != target)
				})
			}
		}
	}

	const profileView = async(studentId : number)=>
	{
		const retrievalError : string = "La información del alumno no pudo ser extraída"
		setUIStatus(UIState.LOCKED)
		dispatch(enableIdleLockSwitch())
		try
		{
			const result : any = await StudentsService.getStudentInfo(studentId)
			if(result.status == 200 || result.status == 204)
			{
				props.profileViewingFn
				({
					...result.data.data, status_id : !result.data.data.bool_enabled ? studentStatus.DISABLED : result.data.data.student.status_id,
					imagePreview : result.data.data.image
				})
			}
			else
			{
				setSaveError(retrievalError)
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			console.log(error)
			setSaveError(retrievalError)
			setUIStatus(UIState.ERROR)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}

	return <DataDisplayModal
		saveSuccess={saveSuccess}
		promptCloseFn={hideEnrollment}
		unchangedRecord={unchangedRecord}
		edittingRefusal={props.selectedEnrollment.status_id == scheduleStatus.ACTIVE ? "" : "Este horario no permite matricular alumnos debido a su estatus (Inactivo)"}
		saveDataFn={saveEnrollment}
		uneditFn={unedit}
		processingError={saveError}
		saveLock={state.isButtonDisabled}
		editableRecord={editableEnrollment}
		dataEditInit={editEnrollment} entityName="Matrícula"
		headIcon={<FontAwesomeIcon
		icon={solid("clipboard-list")} />}
		allowedUpdate={props.selectedEnrollment.status_id == scheduleStatus.ACTIVE && (props.allowance && (props.allowance.create || props.allowance.update || props.allowance.delete))}
		recordFormRef={recordFormRef}
		UIStatus={UIStatus}
		unrequiredEmphasis
	>
		<ModalComponent
			title={Constants.Common.updateEnrollment}
			showModal={showUpdateStudentEnrollment}
			onClose={handleCloseModal}>
			<UpdateStudentEnrollmentComponent
				student={student}
				enrollmentId={enrollmentId}
				onSuccess={handleCloseModal}
			/>
		</ModalComponent>
		<form ref={recordFormRef}
			className="form"
			onSubmit={(event: FormEvent) => {
				event.preventDefault()
				saveEnrollment()
			}}>
			<div className="container">
				<fieldset>
					{
						editableEnrollment == null ? "" : <>
							<legend style={{ display: "flex" }}>
								<button onClick={() => { toggleEdittingList(false) }} className="btn btn-default" disabled={!edittingList}>
									<FontAwesomeIcon icon={solid("circle-info")} size="2x" />
									<span className="d-none d-sm-inline-block" style={{ paddingLeft: "10px" }}>
										Información
									</span>
								</button>
								<button disabled={edittingList} onClick={() => { toggleEdittingList(true) }}
									className="btn btn-default"
								>
									<FontAwesomeIcon icon={solid("clipboard-list")} size="2x" />
									<span className="d-none d-sm-inline-block" style={{ paddingLeft: "10px" }}>
										Inscritos
									</span>
								</button>
							</legend>
							<h3 className="d-block d-xl-none d-lg-none d-md-none d-sm-none">
								{enrollView[+edittingList]}
							</h3>
						</>
					}
					{
						(editableEnrollment && edittingList) ? "" : <>
							<div className="row">
								<div className="col-md-4 text-center">
									<div style={{ maxWidth: "250px", maxHeight: "250px", display: "inline-block" }}>
										<img className="relatedPicture" src=
											{
												(
													(editableEnrollment == null && props.selectedEnrollment.course.image)
													|| (editableEnrollment && editableEnrollment.course.image)
												) || courseImage
											}
										/>
									</div>
									<br />
									<br />
									<div style={{ textAlign: "left" }}>
										<label style={{ position: "relative", textTransform: "uppercase", textAlign: "left" }}>
											código curso
										</label>
										<div style={{ textAlign: "left" }}>
											{props.selectedEnrollment.course.code || "-"}
										</div>
									</div>
									<div style={{ textAlign: "left" }}>
										<label style={{ position: "relative", textTransform: "uppercase", textAlign: "left" }}>
											código módulo
										</label>
										<div style={{ textAlign: "left" }}>
											{props.selectedEnrollment.level.code || "-"}
										</div>
									</div>
									<div style={{ textAlign: "left" }}>
										<label style={{ position: "relative", textTransform: "uppercase", textAlign: "left" }}>
											código horario
										</label>
										<div style={{ textAlign: "left" }}>
											{props.selectedEnrollment.code || "-"}
										</div>
									</div>
									<div className="card card-default" style={{ padding: "15px", marginTop: "15px" }}>
										<label style={{ padding: "unset", position: "relative", textAlign: "left" }}>
											Periodo
										</label>
										<div className="roView" style={{ textAlign: "left" }}>
											{props.selectedEnrollment.period.name}
										</div>
										<label style={{ padding: "unset", position: "relative", textAlign: "left" }}>
											Curso
										</label>
										<div className="roView" style={{ textAlign: "left" }}>
											{props.selectedEnrollment.course.name}
										</div>
										<label style={{ padding: "unset", position: "relative", textAlign: "left" }}>
											Módulo
										</label>
										<div className="roView" style={{ textAlign: "left" }}>
											{props.selectedEnrollment.level.name}
										</div>
										<label style={{ padding: "unset", position: "relative", textAlign: "left" }}>
											Horario
										</label>
										<div className="roView" style={{ textAlign: "left" }}>
											{props.selectedEnrollment.name}
										</div>
										<div className="selectContainer" style={{border : "unset", margin : "unset", height : "unset", minHeight : "48px", paddingTop : "25px"}}>
											<label htmlFor="status_id" style={{textAlign : "left", top : "0px"}}>
												Estatus
											</label>
											<div
											  style=
											  {{
												position : "relative",
												padding : "2px",
												borderRadius : "10px",
												width : "100%",
												borderWidth : "0px 2px 2px 2px",
												borderColor : "#8F91DA",
												background : "#8F91DA",
												textAlign : "left"
											  }}
											>
												<div style={{background : "#8F91DA"}}>
													<div style={{borderRadius : "9px", top : "10px", background : "var(--second-bg)"}}>
														<div className="roView">
															&nbsp;
															{
																basicInfo.scheduleStatusRef["SS_" + props.selectedEnrollment.status_id].isFinalized
																	? `Finalizado (${basicInfo.scheduleStatusRef["SS_" + props.selectedEnrollment.status_id].name})`
																		: basicInfo.scheduleStatusRef["SS_" + props.selectedEnrollment.status_id].name
															}
														</div>
													</div>
												</div>
												{
													props.selectedEnrollment.status_id == scheduleStatus.ACTIVE
															?
														""
															:
														<div style={{padding : "7px 0px 0px 0px", background : "#8F91DA", width : "100%"}}>
															<label
															  htmlFor="status_update_motive"
															  style={{padding : "0px 5px", display : "block", width : "50%", position : "relative", color : "#FFFFFF"}}
															>
																&emsp;
																Motivos:
															</label>
															<div style={{padding : "5px"}}>
																<p>{props.selectedEnrollment.status_update_motive}</p>
															</div>
														</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-8">
									<div className="row">
										{
											(editableEnrollment == null && props.selectedEnrollment.classroom == null) ? "" :
												<div className="col-md-6">
													<label htmlFor="classroom" style={{ position: "unset" }}>
														Aula
													</label>
													{
														props.selectedEnrollment == null || props.selectedEnrollment.classroom == null ? "-" :
															<div className="roView">
																{props.selectedEnrollment.classroom.name}
															</div>
													}
												</div>
										}
										<div className="col-md-6">
											<div className="inputContainer">
												<label htmlFor="students_nimimum">
													Mínimo de estudiantes
												</label>
												<div className="roView">
													<br />
													{props.selectedEnrollment.students_minimum}
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="inputContainer">
												<label htmlFor="students_limit">
													Máximo de estudiantes
												</label>
												<div className="roView">
													<br />
													{props.selectedEnrollment.students_limit}
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<label htmlFor="period_id" style={{ position: "relative" }}>
												Profesor
											</label>
											<div className="roView">{
												(
													(props.selectedEnrollment && props.selectedEnrollment.mentor) &&
													(
														props.selectedEnrollment.mentor.first_name + " " +
														props.selectedEnrollment.mentor.last_name
													)
												)
												||
												"-"
											}</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div
											  className="inputContainer"
											  style={{textAlign : "left" }}
											>
												<label
												  htmlFor="end"
												  style={{position : "relative"}}
												>
													Inicio
												</label>
												<div style={{position : "relative" }}>
													{props.selectedEnrollment.start_date == null ? "-" : revertStringDate(props.selectedEnrollment.start_date)}
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div
											  className="inputContainer"
											  style={{textAlign : "left" }}
											>
												<label
												  htmlFor="end"
												  style={{position : "relative"}}
												>
													Fin
												</label>
												<div style={{position : "relative"}}>
													{props.selectedEnrollment.end_date == null ? "-" : revertStringDate(props.selectedEnrollment.end_date)}
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="inputContainer" style={{ textAlign: "left" }}>
												<label htmlFor="billable_months">
													Meses a facturar
												</label>
												<br />
												{props.selectedEnrollment.billable_months}
											</div>
										</div>
										<div className="col-md-6">
											<div className="inputContainer" style={{ textAlign: "left" }}>
												<label htmlFor="subscription_price">
													Precio de inscripción
												</label>
												<br />
												<div style={{ display: "flex", alignItems: "stretch" }}>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.symbol}
													</div>
													<div style={{ flexGrow: 1, padding: "0px 5px", textAlign: "left" }}>
														<div className="roView"
															style={{ textAlign: "right" }}
														>
															{sprintf("%.02f", props.selectedEnrollment.subscription_price)}
														</div>
													</div>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.code}
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="inputContainer" style={{ textAlign: "left" }}>
												<label htmlFor="monthly_billing_price">
													Precio de mensualidad
												</label>
												<br />
												<div style={{ display: "flex", alignItems: "stretch" }}>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.symbol}
													</div>
													<div style={{ flexGrow: 1, padding: "0px 5px", textAlign: "left" }}>
														<div className="roView"
															style={{ textAlign: "right" }}
														>
															{sprintf("%.02f", props.selectedEnrollment.monthly_billing_price)}
														</div>
													</div>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.code}
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="inputContainer" style={{ textAlign: "left" }}>
												<label htmlFor="subscription_surcharge_cost">
													Recargo de inscripción
												</label>
												<br />
												<div style={{ display: "flex", alignItems: "stretch" }}>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.symbol}
													</div>
													<div style={{ flexGrow: 1, padding: "0px 5px", textAlign: "left" }}>
														<div className="roView"
															style={{ textAlign: "right" }}
														>
															{sprintf("%.02f", props.selectedEnrollment.subscription_surcharge_cost)}
														</div>
													</div>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.code}
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="inputContainer" style={{ textAlign: "left" }}>
												<label htmlFor="monthly_billing_surcharge_cost">
													Recargo de mensualidad
												</label>
												<br />
												<div style={{ display: "flex", alignItems: "stretch" }}>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.symbol}
													</div>
													<div style={{ flexGrow: 1, padding: "0px 5px", textAlign: "left" }}><div className="roView"
														style={{ textAlign: "right" }}
													>
														{sprintf("%.02f", props.selectedEnrollment.monthly_billing_surcharge_cost)}
													</div>
													</div>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.code}
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="inputContainer" style={{ textAlign: "left" }}>
												<label htmlFor="monthly_billing_surcharge_cost">
													Segundo recargo
												</label>
												<br />
												<div style={{ display: "flex", alignItems: "stretch" }}>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.symbol}
													</div>
													<div style={{ flexGrow: 1, padding: "0px 5px", textAlign: "left" }}>
														<div className="roView"
															style={{ textAlign: "right" }}
														>
															{sprintf("%.02f", props.selectedEnrollment.second_surcharge_cost)}
														</div>
													</div>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.code}
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="inputContainer" style={{ textAlign: "left" }}>
												<label htmlFor="monthly_billing_surcharge_cost">
													{Constants.Screens.scheduleModal.mentorFee}
												</label>
												<br />
												<div style={{ display: "flex", alignItems: "stretch" }}>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.symbol}
													</div>
													<div style={{ flexGrow: 1, padding: "0px 5px", textAlign: "left" }}>
														<div className="roView"
															style={{ textAlign: "right" }}
														>
															{sprintf("%.02f", props.selectedEnrollment.mentor_fee)}
														</div>
													</div>
													<div style={{ flexGrow: 0 }}>
														{campusRef[currentUserCampus].company.currency.code}
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="inputContainer" style={{ textAlign: "left" }}>
												<label htmlFor="first_surcharge_month_day">
													{Constants.Screens.scheduleModal.expirationDay1}
												</label>
												<br />
												{props.selectedEnrollment.first_surcharge_month_day}
											</div>
										</div>
										<div className="col-md-6">
											<div className="inputContainer" style={{ textAlign: "left" }}>
												<label htmlFor="first_surcharge_month_day">
													{Constants.Screens.scheduleModal.expirationDay2}
												</label>
												<br />
												{props.selectedEnrollment.second_surcharge_month_day}
											</div>
										</div>
									</div>
									<UnderlineHeading name={"Gestión"} style={{ paddingTop: "15px" }} />
									<table style={{ width: "100%", fontSize: "12px" }}>
										<tbody>
											<tr style={{ color: "#FFFFFF", fontWeight: "200" }}>
												<td className="text-end" />
												<td className="d-none d-sm-table-cell" />
												<td className="d-none d-lg-table-cell" />
												{
													weekDays.map
													(
														(item: any, index: number) => <td key={"d" + index} style=
														  {{ textAlign: "center", borderWidth: "1px", borderColor: "#FFFFFF" }}
														>
															<div style={{ background: "#8F91DA", borderRadius: "3px", display: "flex" }}>
																<span>
																	{item.substr(0, 2)}
																</span>
																<span className="d-none d-lg-inline-block">
																	{item.substr(2)}
																</span>
															</div>
														</td>
													)
												}
											</tr>
										</tbody>
										{
											dayHours.map
											(
												(hour: number) => <tbody key={"h" + hour}>
													<tr>
														<td className="text-end scheduleHeading"
															style={{
																borderWidth: "1px 0px 1px 1px",
																color: "#4A4B80",
																padding: "5px 0px 0px 5px"
															}}>
															<div style={{ borderRadius: "5px 0px 0px 5px" }}>
																{sprintf("%02d", hour)}
															</div>
														</td>
														<td className="d-none d-sm-table-cell scheduleHeading"
															style={{
																borderWidth: "1px 0px 1px 0px",
																color: "#4A4B80",
																paddingTop: "5px",
																width: "1px"
															}}>
															<div>
																:00
															</div>
														</td>
														<td className="d-none d-lg-table-cell scheduleHeading"
															style={{
																borderWidth: "1px 1px 1px 0px",
																color: "#4A4B80",
																padding: "5px 5px 0px 0px"
															}}>
															<div style={{ borderRadius: "0px 5px 5px 0px" }}>
																{sprintf("-%02d:00", hour + 1)}
															</div>
														</td>
														{
															weekDays.map
																(
																	(day: string, dayIndex: number) => {
																		let timeMarks: any =
																			[
																				sprintf("DH%d%03d", dayIndex, hour * 10),
																				sprintf("DH%d%03d", dayIndex, (hour * 10) + 5)
																			]

																		return <td style={{ border: "1px solid #F9F9F9", width: "13%" }}
																			key={"x" + timeMarks[0]}
																		>
																			{
																				timeMarks.map
																					(
																						(timeMark: string, half: number) => <div key={"d" + timeMark} style=
																							{{
																								display: "inline-block", width: "50%",
																								height: "25px", verticalAlign: "bottom",
																								borderRadius: (half ? "0px 5px 5px 0px" : "5px 0px 0px 5px"),
																								background:
																									(
																										props.selectedEnrollment.hours[timeMark]
																											?
																											"var(--main-color)"
																											: "unset"
																									)
																							}}
																						/>
																					)
																			}
																		</td>
																	}
																)
														}
													</tr>
												</tbody>
											)
										}
									</table>
								</div>
							</div>
							<UnderlineHeading name={"Notas"} style={{ paddingTop: "15px" }} />
							<div>
								<p>
									{props.selectedEnrollment.notes || "-"}
								</p>
							</div>
						</>
					}
					{
						(editableEnrollment && !edittingList) ? "" : <div style={{ overflow: "auto" }}>{
							editableEnrollment == null
									?
								<>
									<UnderlineHeading name={"Listado de alumnos"} style={{ paddingTop: "15px" }} />
									{
										(isEmpty(props.selectedEnrollment.enrollments) && isEmpty(props.selectedEnrollment.nonActiveEnrollments))
												?
											 "(Sin registros)" 
												: 
											<table className="commonTable">
											<thead>
												<tr>
													<th style={{borderBottom : "unset"}} colSpan={4} />
													<th 
													  style={{borderBottom : "unset", textAlign : "center", whiteSpace : "nowrap", color : "#ABACE3", fontSize : 14}}
													  colSpan={2}
													>
														Saldo mensualidades
													</th>
													<th style={{borderBottom : "unset"}} colSpan={4} />
												</tr>
												<tr>
													<th
													  style={{color : "#ABACE3", fontSize : 14, verticalAlign : "top"}}
													  ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #000000", "important")}}
													>
														Matrícula
													</th>
													<th style={{color : "#ABACE3", fontSize : 14, verticalAlign : "top"}}>
														Nombre
													</th>
													<th style={{color : "#ABACE3", fontSize : 14, verticalAlign : "top"}}>
														Teléfonos
													</th>
													<th style={{color : "#ABACE3", fontSize : 14, verticalAlign : "top"}}>
														Estatus matrícula
													</th>
													<th style={{color : "#ABACE3", fontSize : 14, verticalAlign : "top", textAlign : "center"}}>
														Vencido
													</th>
													<th style={{color : "#ABACE3", fontSize : 14, verticalAlign : "top", textAlign : "center"}}>
														Total
													</th>
													<th style={{color : "#ABACE3", fontSize : 14, verticalAlign : "top"}}>
														Saldo kits
													</th>
													<th style={{color : "#ABACE3", fontSize : 14, verticalAlign : "top"}}>
														Saldo otros
													</th>
													<th style={{color : "#ABACE3", fontSize : 14, verticalAlign : "top"}}>
														Crédito a favor
													</th>
													<th />
												</tr>
											</thead>
											<tbody>{
												props.selectedEnrollment.enrollments.map
												(
													(enrollment: any)=>
													{
														const kitsBalance: number = +enrollment.kit_debt - +enrollment.kit_debt_settlement
														const tuitionsBalance: number = +enrollment.tuition_debt - +enrollment.tuition_debt_settlement

														return <tr
														  key={"IS_" + enrollment.enrollmentId}
														  style={{ color: ((kitsBalance + tuitionsBalance) > 0 ? "#BA0000" : "#000000") }}
														>
															<td ref={el => { if (el) el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") }}>
																{enrollment.id}
															</td>
															<td>
																{enrollment.firstName + " " + enrollment.lastName}
															</td>
															<td>
																{[enrollment.phone1, enrollment.phone2].filter((phone: string) => isNotEmpty(phone)).join(", ") || "-"}
															</td>
															<td>
																<Badge bg={Utils.renderStatusEnrollment(enrollment.enrollmentStatusId)}>
																	{enrollment.enrollmentStatus}
																</Badge>
															</td>
															<td style={{whiteSpace : "nowrap", textAlign : "right", color : enrollment.generalBalance.expiredMonthlyBalance.value == 0 ? "#000000" : "#C32722"}}>
																{enrollment.generalBalance.expiredMonthlyBalance.moneyFormat}
															</td>
															<td style={{whiteSpace : "nowrap", textAlign : "right", color : enrollment.generalBalance.monthlyBalance.value == 0 ? "#000000" : "#3B5560"}}>
																{enrollment.generalBalance.monthlyBalance.moneyFormat}
															</td>
															<td style={{whiteSpace : "nowrap", textAlign : "right", color : enrollment.generalBalance.kitsBalance.value == 0 ? "#000000" : "#C32722"}}>
																{enrollment.generalBalance.kitsBalance.moneyFormat}
															</td>
															<td style={{whiteSpace : "nowrap", textAlign : "right", color : enrollment.generalBalance.othersBalance.value == 0 ? "#000000" : "#C32722"}}>
																{enrollment.generalBalance.othersBalance.moneyFormat}
															</td>
															<td style={{whiteSpace : "nowrap", textAlign : "right", color : enrollment.generalBalance.positiveBalance.value == 0 ? "#000000" : "#00FF00"}}>
																{enrollment.generalBalance.positiveBalance.moneyFormat}
															</td>
															<td>
																<div style={{display : "flex"}}>
																	<OverlayTrigger overlay={
																		<Tooltip id="tooltip-activos">
																			{Constants.Common.showDetail}
																		</Tooltip>}>
																		<button
																		  type="button"
																		  disabled={idleLocked}
																		  className="btn btn default"
																		  onClick={() => profileView(enrollment.id)}
																		>
																			<FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
																		</button>
																	</OverlayTrigger>
																	<OverlayTrigger overlay={
																		<Tooltip id="tooltip-activos">
																			{Constants.Common.goToAccountStatement}
																		</Tooltip>}>
																		<button onClick={() => navigateTo(enrollment)}
																			disabled={idleLocked}
																			className="btn btn default"
																			type="button"
																		>
																			<FontAwesomeIcon icon={solid("money-bills")} />
																		</button>
																	</OverlayTrigger>
																	{
																		(props.allowance == null || !props.allowance.delete) ? "" : <OverlayTrigger
																			overlay=
																			{
																				<Tooltip id="tooltip-activos">
																					{Constants.Common.updateEnrollment}
																				</Tooltip>
																			}
																		>
																			<button
																			  onClick={() => updateStudentEnrollment(enrollment)}
																			  disabled={idleLocked}
																			  className="btn btn default"
																			  type="button"
																			>
																				<FontAwesomeIcon icon={solid("clipboard-list")} flip="horizontal" />
																			</button>
																		</OverlayTrigger>
																	}
																</div>
															</td>
														</tr>
													}
												)
											}</tbody>
											{
												isEmpty(props.selectedEnrollment.nonActiveEnrollments) ? "" : <>
													<tbody>
														<tr>
															<td
															  colSpan={10}
															  style=
															  {{
																borderWidth : "1px 1px 1px 1px",
																borderRadius : "15px 15px 0px 0px",
																borderStyle : "solid",
																padding : "5px 10px 0px 10px",
																borderColor : "#DADADA"
															  }}
															>
																Matrículas no activas:
															</td>
														</tr>
													</tbody>
													<tbody className="disabledTable">{
														props.selectedEnrollment.nonActiveEnrollments.map
														(
															(enrollment : any)=>
															{
																const kitsBalance: number = +enrollment.kit_debt - +enrollment.kit_debt_settlement
																const tuitionsBalance: number = +enrollment.tuition_debt - +enrollment.tuition_debt_settlement

																return <tr
																	key={"IS_" + enrollment.enrollmentId}
																	style={{ color: ((kitsBalance + tuitionsBalance) > 0 ? "#BA0000" : "#000000") }}
																>
																	<td ref={el => { if (el) el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") }}>
																		{enrollment.id}
																	</td>
																	<td>
																		{enrollment.firstName + " " + enrollment.lastName}
																	</td>
																	<td>
																		{[enrollment.phone1, enrollment.phone2].filter((phone: string) => isNotEmpty(phone)).join(", ") || "-"}
																	</td>
																	<td>
																		<Badge bg={Utils.renderStatusEnrollment(enrollment.enrollmentStatusId)}>
																			{enrollment.enrollmentStatus}
																		</Badge>
																	</td>
																	<td style={{whiteSpace : "nowrap", textAlign : "right", color : enrollment.generalBalance.expiredMonthlyBalance.value == 0 ? "#000000" : "#C32722"}}>
																		{enrollment.generalBalance.expiredMonthlyBalance.moneyFormat}
																	</td>
																	<td style={{whiteSpace : "nowrap", textAlign : "right", color : enrollment.generalBalance.monthlyBalance.value == 0 ? "#000000" : "#3B5560"}}>
																		{enrollment.generalBalance.monthlyBalance.moneyFormat}
																	</td>
																	<td style={{whiteSpace : "nowrap", textAlign : "right", color : enrollment.generalBalance.kitsBalance.value == 0 ? "#000000" : "#C32722"}}>
																		{enrollment.generalBalance.kitsBalance.moneyFormat}
																	</td>
																	<td style={{whiteSpace : "nowrap", textAlign : "right", color : enrollment.generalBalance.othersBalance.value == 0 ? "#000000" : "#C32722"}}>
																		{enrollment.generalBalance.othersBalance.moneyFormat}
																	</td>
																	<td style={{whiteSpace : "nowrap", textAlign : "right", color : enrollment.generalBalance.positiveBalance.value == 0 ? "#000000" : "#00FF00"}}>
																		{enrollment.generalBalance.positiveBalance.moneyFormat}
																	</td>
																	<td>
																		<div style={{ display: "flex" }}>
																			<OverlayTrigger overlay={
																				<Tooltip id="tooltip-activos">
																					{Constants.Common.showDetail}
																				</Tooltip>}>
																				<button
																				  type="button"
																				  disabled={idleLocked}
																				  className="btn btn default"
																				  onClick={() => profileView(enrollment.id)}
																				>
																					<FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
																				</button>
																			</OverlayTrigger>
																			<OverlayTrigger overlay={
																				<Tooltip id="tooltip-activos">
																					{Constants.Common.goToAccountStatement}
																				</Tooltip>}>
																				<button
																				  onClick={() => navigateTo(enrollment)}
																				  disabled={idleLocked}
																				  className="btn btn default"
																				  type="button"
																				>
																					<FontAwesomeIcon icon={solid("money-bills")} />
																				</button>
																			</OverlayTrigger>
																			{
																				(props.allowance == null || !props.allowance.delete) ? "" : <OverlayTrigger
																					overlay=
																					{
																						<Tooltip id="tooltip-activos">
																							{Constants.Common.viewEnrollment}
																						</Tooltip>
																					}
																				>
																					<button
																						onClick={() => updateStudentEnrollment(enrollment)}
																						disabled={idleLocked}
																						className="btn btn default"
																						type="button"
																					>
																						<FontAwesomeIcon icon={solid("clipboard-list")} flip="horizontal" />
																					</button>
																				</OverlayTrigger>
																			}
																		</div>
																	</td>
																</tr>
															}
														)
													}</tbody>
												</>
											}
										</table>
									}
								</>
									:
								<>
									{
										!props.allowance.create ? "" : <Popover
											isOpen={isPopoverOpen}
											positions={["bottom"]}
											onClickOutside={() => setIsPopoverOpen(false)}
											content=
											{<div style={{ overflowY: "auto", maxHeight: "40vh", background: "var(--main-bg)", width: "85vw" }}>
												{
													(studentsToEnroll == null || studentsToEnroll.length < 1) ? "" : studentsToEnroll.filter((student: any) => student).map
													(
														(student: any, index: number) => <div
															key={"T_" + index}
															style={{display: "inline-grid", padding: "5px", width: "100%"}}
														>
															{
																(student.expiredMonthlyBalance > 0  && userProfile.currentRole !== ROLE.SUPER_ADMIN)
																		?
																	<button
																		key={"T_" + index}
																		type="button"
																		className="btn btn-default bg-danger"
																		disabled={idleLocked || financeTrammelRef["S_" + student.id]}
																		onClick={() => financeTrammelPrompt(student)}
																		style=
																		{{
																			whiteSpace: "nowrap",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			textAlign: "left",
																			color: "#FFFFFF",
																			position: "relative"
																		}}
																	>
																		{
																			financeTrammelRef["S_" + student.id] == null ? "" : <span
																			  className="usageEmphasis"
																			  style={{ height: "38px", position: "absolute" }}
																			>
																				<h4 style={{ color: "#FFFFFF" }}>
																					[!] Alumno con mensualidades vencidas
																				</h4>
																			</span>
																		}
																		<span style={{ display: "block" }}>
																			<FontAwesomeIcon icon={solid("ban")} />
																			<span style={{ paddingLeft: "10px" }}>
																				{student.first_name + " " + student.last_name}
																			</span>
																		</span>
																	</button>
																		:
																	(
																		(student.matchingSessions || student.matchingLevel)
																				?
																			<button
																			  key={"T_" + index}
																			  type="button"
																			  className="btn-unstyled callout callout-warning"
																			  disabled={idleLocked || matchTrammelRef["S_" + student.id]}
																			  onClick={() => matchTrammelPrompt(student)}
																			  style=
																			  {{
																				whiteSpace: "nowrap",
																				overflow: "hidden",
																				textOverflow: "ellipsis",
																				textAlign: "left",
																				position: "relative",
																				margin : "unset",
																				border : "unset",
																				borderRadius : "5px",
																				padding : "7px 12px"
																			  }}
																			>
																				{
																					matchTrammelRef["S_" + student.id] == null ? "" : <span
																					  className="usageEmphasis"
																					  style={{height : "38px", position : "absolute", background : "#FFFF00"}}
																					>
																						<h4>
																							[!]
																							&nbsp;
																							{
																								student.matchingSessions
																									? "Conflicto de horario"
																										: "Mismo nivel, diferente horario"
																							}
																						</h4>
																					</span>
																				}
																				<span style={{ display: "block" }}>
																					<FontAwesomeIcon icon={student.matchingSessions ? regular("calendar-xmark") : regular("calendar-check")} />
																					<span style={{ paddingLeft: "10px" }}>
																						{student.first_name + " " + student.last_name}
																					</span>
																				</span>
																			</button>
																				:
																			<button
																			  key={"T_" + index}
																			  type="button"
																			  onClick={() => enrollStudent(student)}
																			  className=
																			  {
																					studentUI
																					[
																						(+(enrollmentInit[student.refIndex] != null)) +
																							(+(enrollmentAdding[student.refIndex] != null))
																					]
																			  } disabled=
																			  {
																					(student.matchingSessions || student.matchingLevel)
																						||
																					(idleLocked || enrollmentAdding[student.refIndex])
																			  }
																			  style=
																			  {{
																					whiteSpace : "nowrap",
																					overflow : "hidden",
																					textOverflow : "ellipsis",
																					textAlign : "left"
																			  }}
																			>
																				<FontAwesomeIcon icon=
																				  {
																					student.matchingLevel
																							?
																						regular("calendar-check")
																							:
																						(
																							student.matchingSessions
																									?
																								regular("calendar-xmark")
																									:
																								(
																									enrollStatus
																									[
																										(+(enrollmentInit[student.refIndex] != null)) +
																										(+(enrollmentAdding[student.refIndex] != null))
																									]
																								)
																						)
																				  }
																				/>
																				<span style={{ paddingLeft: "10px" }}>
																					{student.first_name + " " + student.last_name}
																				</span>
																			</button>
																	)
															}
															</div>
														)
												}
												<div className="text-center" style={{ color: "#C7C7C7" }}>
													No hay más resultados.
												</div>
											</div>}
										>
											<div className="inputContainer" style={{ position: "sticky", left: "0" }}>
												<label
													htmlFor="listSearch"
													style={{ position: "relative", display: "block" }}
												>
													Nombres, apellidos, correo, ...
													<div style={{ height: "5px" }}>
														{!aSearchInProgress ? "" : <div
															className="loader"
															style={{ flex: 1 }} />
														}
													</div>
												</label>
												<input
													id="listSearch"
													onClick={() => setIsPopoverOpen(!isPopoverOpen)}
													ref={nameRef}
													type="text"
													style={{ width: "100%" }}
													value={listSearch}
													placeholder="Nombres, apellidos, correo"
													maxLength={50}
													onChange={(e: any) => searchStudents(e.target.value)}
													name="listSearch"
													disabled={idleLocked}
												/>
											</div>
										</Popover>
									}
									{
										props.selectedEnrollment.level.kits && props.selectedEnrollment.level.kits.length > 0 ? "" :
											<table
												style={{ width: "100%", position: "sticky", left: "0px" }}
												className="callout callout-warning"
											>
												<tbody>
													<tr>
														<td style={{ padding: "16px", verticalAlign: "middle" }}>
															<FontAwesomeIcon icon={solid("triangle-exclamation")} />
														</td>
														<td style={{ verticalAlign: "middle" }}>
															No hay kits vinculados a este módulo para ser asignados a los alumnos
														</td>
													</tr>
												</tbody>
											</table>
									}
									{
										(editableEnrollment == null || (isEmpty(editableEnrollment.enrollments) && isEmpty(editableEnrollment.nonActiveEnrollments))) ? "" : <>
											<table className="standardTable">
												<thead>
													<tr key="usersHeader">
														<th style={{ width: "1px" }}
														  ref={el => { if (el) { el.style.setProperty("border-bottom", "1px solid #000000", "important") } }}
														>
															id
														</th>
														<th>
															<FontAwesomeIcon icon={solid("image")} />
														</th>
														<th>
															Nombres
														</th>
														<th>
															Apellidos
														</th>
														<th>
															Código de identidad
														</th>
														<th>
															Email
														</th>
														<th style={{ minWidth: "235px" }}>
															Kits
														</th>
														<th />
													</tr>
												</thead>
												<tbody>{
													editableEnrollment
														.enrollments
														.filter((enrollment : any) => enrollment)
														.map
														(
															(listedEnrollment: any, index: number) => (enrollmentInit[listedEnrollment.refIndex] == null)
																	?
																""
																	:
																<tr key={"user" + index}>
																	<td>
																		{listedEnrollment.id}
																	</td>
																	<td style={{ width: "1px" }}>
																		<img src={listedEnrollment.image != null ? listedEnrollment.image : userImage}
																		  className="detailPicture"
																		/>
																	</td>
																	<td>
																		{listedEnrollment.firstName || "-"}
																	</td>
																	<td>
																		{listedEnrollment.lastName || "-"}
																	</td>
																	<td>
																		{listedEnrollment.identitCode || "-"}
																	</td>
																	<td>
																		{listedEnrollment.email}
																	</td>
																	<td>
																		{
																			listedEnrollment.invoicedKits.lenght < 1 ? "" : listedEnrollment.invoicedKits.map
																				(
																					(kit: any) => <div className="badge rounded-pill bg-primary"
																						key={"IK" + listedEnrollment.id + "_" + kit.id}
																					>
																						{kit.name}
																					</div>
																				)
																		}
																		{
																			listedEnrollment.extraKits.lenght < 1 ? "" : listedEnrollment.extraKits.map
																				((kit: any, kitIndex: number) =>
																					<div
																						key={"EXK" + listedEnrollment.id + "_" + kit.id}
																						className=
																						{
																							"badge rounded-pill " +
																							(kit.toBeDeleted ? "btn-danger" : "btn-primary")
																						}
																					>
																						{
																							!props.allowance.update ? "" : <button
																								disabled={idleLocked}
																								className={"btn btn-default"}
																								type="button"
																								onClick=
																								{
																									() => {
																										setEditableEnrollment
																											({
																												...editableEnrollment, enrollments: editableEnrollment.enrollments.map
																													(
																														(enrollment: any) =>
																														({
																															...enrollment, extraKits:
																																(
																																	listedEnrollment.id != enrollment.id ? enrollment.extraKits : enrollment.extraKits.map((extraKit: any, extraKitIndex: number) =>
																																	({
																																		...extraKit, toBeDeleted:
																																			(
																																				extraKitIndex != kitIndex ? extraKit.toBeDeleted :
																																					(extraKit.toBeDeleted == null ? true : !extraKit.toBeDeleted)
																																			)
																																	}))
																																)
																														})
																													)
																											})
																									}
																								}
																							>
																								<FontAwesomeIcon icon={kit.toBeDeleted != true ? solid("times") : solid("undo")} />
																							</button>
																						}
																						{kit.name}
																					</div>
																				)
																		}
																		{
																			(!props.allowance.update && listedEnrollment.availableKits.length < 1)
																					?
																				""
																					:
																				listedEnrollment.availableKits.length < 1 ? "(Sin registros)" : <Multiselect
																					displayValue="name"
																					disable={!props.allowance.update || idleLocked}
																					placeholder="Seleccione"
																					options={listedEnrollment.availableKits}
																					selectedValues={listedEnrollment.editableKits}
																					onSelect=
																					{
																						(kits) => {
																							setEditableEnrollment
																								({
																									...editableEnrollment, enrollments: editableEnrollment.enrollments.map
																										(
																											(enrollment: any) =>
																											({
																												...enrollment, editableKits: enrollment.id != listedEnrollment.id
																													? enrollment.editableKits
																													: kits
																											})
																										)
																								})
																						}
																					} onRemove=
																					{
																						(kits)=>
																						{
																							setEditableEnrollment
																							({
																								...editableEnrollment, enrollments: editableEnrollment.enrollments.map
																								(
																									(enrollment: any) =>
																									({
																										...enrollment, editableKits: enrollment.id != listedEnrollment.id
																											? enrollment.editableKits
																												: kits
																									})
																								)
																							})
																						}
																					}
																					emptyRecordMsg="No hay más opciones"
																				/>
																		}
																	</td>
																	<td />
																</tr>
														)
												}</tbody>
												<tbody className="uncommonTable">{/*Newly added students*/
													editableEnrollment
														.enrollments
														.filter((enrollment: any) => enrollment)
														.map
														(
															(listedEnrollment: any, index: number) => enrollmentAdding[listedEnrollment.refIndex] == null ? "" : <tr key={"user" + index}>
																<td>
																	{listedEnrollment.id}
																</td>
																<td style={{ width: "1px" }}>
																	<img className="detailPicture" src={listedEnrollment.image != null ? listedEnrollment.image : userImage} />
																</td>
																<td>
																	{listedEnrollment.first_name || "-"}
																</td>
																<td>
																	{listedEnrollment.last_name || "-"}
																</td>
																<td>
																	{listedEnrollment.student.identity_code || "-"}
																</td>
																<td>
																	{listedEnrollment.email}
																</td>
																<td>
																	{props.selectedEnrollment.level.kits.length < 1 ? "(Sin registros)" : <Multiselect
																			placeholder="Seleccione"
																			displayValue="name"
																			emptyRecordMsg="No hay más opciones"
																			selectedValues={listedEnrollment.editableKits}
																			options={props.selectedEnrollment.level.kits}
																			onSelect=
																			{
																				(kits)=>
																				{
																					setEditableEnrollment
																					({
																						...editableEnrollment, enrollments:
																							editableEnrollment.enrollments.map
																							(
																								(student: any)=>
																								({
																									...student, editableKits: student.id != listedEnrollment.id
																										? student.editableKits
																											: kits
																								})
																							)
																					})
																				}
																			}
																			onRemove=
																			{
																				(kits)=>
																				{
																					setEditableEnrollment
																					({
																						...editableEnrollment, enrollments : editableEnrollment.enrollments.map
																						(
																							(student: any)=>
																							({
																								...student, editableKits: student.id != listedEnrollment.id
																									? student.editableKits
																									: kits
																							})
																						)
																					})
																				}
																			}
																		/>
																	}
																</td>
																<td>
																	<div style={{ display: "flex" }}>
																		<button
																			disabled={idleLocked}
																			style={{ display: "table-column" }}
																			className="button btn btn-default"
																			type="button"
																			onClick={() => listRemoval(index)}
																		>
																			<FontAwesomeIcon icon={solid("times")} />
																		</button>
																	</div>
																</td>
															</tr>
														)
												}
												</tbody>
												{
													isEmpty(props.selectedEnrollment.nonActiveEnrollments) ? "" : <>
														<tbody>
															<tr>
																<td
																	colSpan={8}
																	style=
																	{{
																		borderWidth: "1px 1px 1px 1px",
																		borderRadius: "15px 15px 0px 0px",
																		borderStyle: "solid",
																		padding: "5px 10px 0px 10px",
																		borderColor: "#DADADA"
																	}}
																>
																	Matrículas no activas:
																</td>
															</tr>
														</tbody>
														<tbody className="disabledTable">{
															props.selectedEnrollment.nonActiveEnrollments.filter((enrollment: any) => enrollment).map
															(
																(enrollment: any, index: number) => <tr key={"user" + index}>
																	<td>
																		{enrollment.id}
																	</td>
																	<td style={{ width: "1px" }}>
																		<img
																		  src={enrollment.image != null ? enrollment.image : userImage}
																		  className="detailPicture"
																		/>
																	</td>
																	<td>
																		{enrollment.firstName || "-"}
																	</td>
																	<td>
																		{enrollment.lastName || "-"}
																	</td>
																	<td>
																		{enrollment.identityCode || "-"}
																	</td>
																	<td>
																		{enrollment.email}
																	</td>
																	<td>
																		{
																			enrollment.invoicedKits.lenght < 1 ? "" : enrollment.invoicedKits.map
																			(
																				(kit: any) => <div
																				  className="badge rounded-pill bg-primary"
																				  key={"IK" + enrollment.id + "_" + kit.id}
																				>
																					{kit.name}
																				</div>
																			)
																		}
																		{
																			enrollment.extraKits.lenght < 1 ? "" : enrollment.extraKits.map
																				((kit: any, kitIndex: number) =>
																					<div
																					  key={"EXK" + enrollment.id + "_" + kit.id}
																					  className={"badge rounded-pill bg-primary"}
																					>
																						{kit.name}
																					</div>
																				)
																		}
																		{
																			enrollment.editableKits.lenght < 1 ? "" : enrollment.editableKits.map
																				((kit: any, kitIndex: number) =>
																					<div
																					  key={"EDK" + enrollment.id + "_" + kit.id}
																					  className={"badge rounded-pill bg-primary"}
																					>
																						{kit.name}
																					</div>
																				)
																		}
																	</td>
																	<td />
																</tr>
															)
														}</tbody>
													</>
												}
												<tbody>
												</tbody>
											</table>
										</>
									}
								</>
						}</div>
					}
				</fieldset>
			</div>
		</form>
	</DataDisplayModal>
}

export default EnrollmentPrompt
