//imports
import { useSelector } from "react-redux";
import { RootState } from "../../../globals";
import { FullSizeModalControllerProps } from "./interfaces";

//view
import FullSizeModalView from "./fullSizeModalView";

const FullSizeModalController = ({
    title,
    show,
    headerContent,
    bodyContent,
    footerContent,
    onClose,
}: FullSizeModalControllerProps) => {

    //useSelector
    const idleLocked : boolean = useSelector((state : RootState) => state.idleLockSwitch.value);

    return (
        show?
        <FullSizeModalView
            title = {title}
            onClose = {onClose}
            idleLocked = {idleLocked}
            headerContent = {headerContent}
            bodyContent = {bodyContent}
            footerContent = {footerContent}
        />
        :
        <></>
    )
}

export default FullSizeModalController