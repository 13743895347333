import
{
	useState,
	useRef,
	useReducer,
	useEffect
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import
{
	OverlayTrigger,
	Tooltip
}
	from "react-bootstrap"
import Multiselect
	from "multiselect-react-dropdown"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import _
	from "lodash"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	dismissNotice
}
	from "./../../globals"

import
{
	getAccessPermissions,
	imageTypes,
	isEmpty,
	isNotEmpty,
	generateRandomPassword,
	pageGrouping,
	UIState,
	getErrorMessage,
}
	from "./../../common"
import Constants,
{
	tunning,
	employeeStatus
}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer
	from "./../../components/sectionContainer"
import EmployeeInfoModal
	from "./employeeInfoModal"
import StudentInfoModal
	from "./studentInfoModal"
import RolesService
	from "./../../services/roles.service"
import EmployeesService
	from "./../../services/campus/employees.service"
import CatalogService
	from "./../../services/catalog.service"
import defaultUserImage
	from "./../../assets/images/user.png"

import "./../../components/dropdown/style.css"
import "./../../components/input/style.css"
import {sprintf} from "sprintf-js"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action   = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled":
			return{ ...state, isButtonDisabled : action.payload}
		break;
	}
}

const Employees = ()=>
{
	const ROLES            : number = 0
	const ACADEMIC_DEGREES : number = 1

	const [extendedFilterShown, setExtendedFilterShown] = useState<boolean>(false)
	const [roles, setRoles] = useState<any[]>([])
	const [state, trigger] = useReducer(reducer, initialState)
	const dispatch = useDispatch()
	const [totalRecords, setTotalRecords] = useState<number>(0)
	const [initSetup, setInitSetup] = useState<number | null>(null)
	const [data, setData] = useState<any[]>([])
	const [readOnlyData, setReadOnlyData] = useState<any[]>([])
	const [academicDegrees, setAcademicDegrees] = useState<any>([])
	const [warningReport, listWarnings] = useState<any>({})
	const currentUser  : any = useSelector((state : RootState) => state.userProfile.value)
	const idleLocked   : boolean = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle  : boolean = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo    : any = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any = useSelector((state : RootState) => state.currentUserCampus.value)
	const employeeStatuses  : any = Object.values(basicInfo.employeeStatusRef)
	const campusRef         : any = useSelector((state : RootState) => state.campusRef.value)
	const [campuses, setCampuses] = useState<any>(Object.values(campusRef))
	const [userCampus, setUserCampus] = useState<number>(currentUserCampus)
	const [unfilledMainData, setUnfilledMainData] = useState<boolean>(false)
	const [noRolesProvided, setNoRolesProvided] = useState<boolean>(false)
	const [wrongRolesProvided, setWrongRolesProvided] = useState<boolean>(false)
	const [wrongValueOnFields, setWrongValueOnFields] = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields] = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord] = useState<boolean>(false)
	const [noCityProvided, setNoCityProvided] = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded] = useState<boolean>(false)
	const [roleView, setRoleView] = useState<boolean>(false)
	const [moduleReady, setModuleReady] = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [UIStatus, setUIStatus] = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords] = useState<number>(0)
	const [validRoles, setValidRoles] = useState<number>(0)
	const [minYear, setMinYear] = useState<number>(0)
	const [maxYear, setMaxYear] = useState<number>(0)
	const [newEmployee, setNewEmployee] = useState<any>({})
	const [selectedEmployee, setSelectedEmployee] = useState<any>(null)
	const [editableEmployee, setEditableEmployee] = useState<any>(null)
	const [selectedStudent, setSelectedStudent] = useState<any>(null)
	const [yearsToAlmostPresent, setYearsToAlmostPresent] = useState<any>([])
	const [minDate, setMinDate] = useState<any>(null)
	const [maxDate, setMaxDate] = useState<any>(null)
	const [date, setDate] = useState<any>(null)
	const [pagination, setPagination] = useState<any>(null)
	const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
	const [saveError, setSaveError] = useState<any>(null)
	const [currentDate, setCurrentDate] = useState<any>(null)
	const [recordsSelection, setRecordsSelection] = useState<any>([])
	const [currentTab, setCurrentTab] = useState<string | null>("mainInfo")
	const count        : number[] = [-1, 1]
	let   searchFormRef = useRef<HTMLFormElement>(null)
	let   recordFormRef = useRef<HTMLFormElement >(null)
	let   firstNameRef = useRef<HTMLInputElement>(null)
	let   lastNameRef = useRef<HTMLInputElement>(null)
	let   zipcodeRef = useRef<HTMLInputElement>(null)
	let   imageRef = useRef<HTMLInputElement>(null)
	let   observationsRef = useRef<HTMLTextAreaElement>(null)
	let   countrySelRef = useRef<HTMLSelectElement>(null)
	let   stateSelRef = useRef<HTMLSelectElement>(null)
	let   stateNameRef = useRef<HTMLInputElement>(null)
	let   cityNameRef = useRef<HTMLInputElement>(null)
	let   addressRef = useRef<HTMLInputElement>(null)
	let   emailRef = useRef<HTMLInputElement>(null)
	let   nationalitySelRef : any = useRef<HTMLSelectElement>(null)
	let   identificationCodeRef = useRef<HTMLInputElement>(null)
	let   passwordRef = useRef<HTMLInputElement>(null)
	let   phone1Ref = useRef<HTMLInputElement>(null)
	let   phone2Ref = useRef<HTMLInputElement>(null)
	let   idClase365Ref = useRef<HTMLInputElement>(null)
	let   tabDivRef = useRef<HTMLDivElement>(null)
	let   roleSelRef = useRef<Multiselect | null>(null)
	const allowed          : any = getAccessPermissions(currentUser)
	let   fileReader       : FileReader
	const defaultSearch=
	{
		q        : "",
		statusId : employeeStatus.ACTIVE,
		tags     : [],
	}
	const [customSearch, setCustomSearch]                 = useState<any>(defaultSearch)
	const [linkInfo, setLinkInfo] = useState<any>
	({
		facebook   : "Facebook",
		instagram  : "Instagram"
	})
	const [showPassword, setShowPassword] = useState(false)
	const toggleShowPassword              = ()=> setShowPassword(!showPassword)

	const unedit = ()=>
	{
		if(editableEmployee && editableEmployee.id == null)
		{
			setSelectedEmployee(null)
		}

		setEditableEmployee(null)
	}

	const hidePrompt = ()=>
	{
		setSelectedEmployee(null)
		setEditableEmployee(null)
	}

	const showPrompt = (recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)
		setSaveError(null)
		setRoleView(false)

		let index   : string
		let roleMap : any = {}

		recordData.roles.map
		(
			(role : any)=>
			{
				index = "R_" + role.id

				if(roleMap["R_" + role.id] == null)
				{
					roleMap["R_" + role.id]=
					{
						...role,
						valid : true
					}
				}

				if(Object.keys(campusRef).length > 1)
				{
					if(roleMap["R_" + role.id].campuses == null)
					{
						roleMap["R_" + role.id].campuses = []
					}

					if(campusRef["C_" + role.pivot.campus_id])
					{
						roleMap["R_" + role.id].campuses.push(campusRef["C_" + role.pivot.campus_id])
					}
				}
			}
		)

		let data=
		{
			...recordData, roles : Object.values(roleMap),
			password             : generateRandomPassword(10),
			imagePreview         : recordData.image
		}

		setValidRoles(recordData.roles.length)
		setCurrentTab("mainInfo")

		let cautionDate = new Date(date + " 23:00:00")
		let maximumDate = new Date(cautionDate)

		maximumDate.setFullYear(maximumDate.getFullYear() -  18)
		setMaxDate(maximumDate)
		setMinDate(new Date(sprintf("%04d-01-01 23:00:00", minYear)))

		if(recordData.id == null)
		{
			setEditableEmployee(data)
		}

		setSelectedEmployee(data)
	}

	const saveRecord = ()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)

					if(currentTab != "roles")
					{
						recordFormRef.current.reportValidity()
					}
					else
					{
						tabDivRef?.current?.focus()
						setUnfilledMainData(true)
					}

					setTimeout
					(
						() =>
						{
							setUnfilledMainData(false)
							setUnfilledFields(false)
						},
						3000
					)
				}
			}
			else
			{
				if
				(
					(
						basicInfo.countryRef["C_" + editableEmployee.country_id].states.length > 0 &&
						basicInfo.countryRef["C_" + editableEmployee.country_id].stateRef["S_" + editableEmployee.state_id].cities.length > 0
					)
						&&
					(editableEmployee.city == null || editableEmployee.city.id == null)
				)
				{
					if(!unfilledFields)
					{
						setUnfilledFields(true)

						if(currentTab != "roles")
						{
							setNoCityProvided(true)
						}
						else
						{
							setUnfilledMainData(true)
							tabDivRef?.current?.focus()
						}

						setTimeout
						(
							() =>
							{
								setCustomSearch(defaultSearch)
								setUnfilledMainData(false)
								setUnfilledFields(false)
								setNoCityProvided(false)
							},
							3000
						)
					}
				}
				else
				{
					if(editableEmployee.roles.length < 1)
					{
						if(!unfilledFields && !noRolesProvided)
						{
							tabDivRef?.current?.focus()
							setUnfilledFields(true)
							setNoRolesProvided(true)

							setTimeout
							(
								() =>
								{
									setNoRolesProvided(false)
									setUnfilledFields(false)
								},
								3000
							)
						}
					}
					else
					{
						if((campuses.length > 1) && (validRoles < editableEmployee.roles.length))
						{
							if(!unfilledFields && !wrongRolesProvided && !wrongValueOnFields)
							{
								tabDivRef?.current?.focus()
								setWrongRolesProvided(true)
								setWrongValueOnFields(true)

								setTimeout
								(
									() =>
									{
										setWrongRolesProvided(false)
										setWrongValueOnFields(false)
									},
									3000
								)
							}
						}
						else
						{
							if(saveError != null)
							{
								setSaveError(null)
							}

							setUIStatus(UIState.LOCKED)
							dispatch(enableIdleLockSwitch());

							EmployeesService.saveEmployee(editableEmployee).then
							(
								()=>
								{
									setSaveSuccess(true)
									setUIStatus(UIState.SUCCESS)

									setTimeout
									(
										()=>
										{
											setSelectedEmployee(null)
											setEditableEmployee(null)
											setSaveSuccess(false)
											setUIStatus(UIState.NORMAL)
											search()
										},
										tunning.MODAL_DISMISS_DELAY
									)
								},
								(error : any)=>
								{
									console.log(error)
									setUIStatus(UIState.ERROR)
									dispatch(disableIdleLockSwitch())
									setSaveError(getErrorMessage(error))
								}
							)
						}
					}
				}
			}
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch
			({
				...customSearch,
				[e.target.name] : e.target.value
			})
		}
	}

	const imageLoad = (event : any)=>
	{
		if(event && event.target && event.target.files && event.target.files.length > 0)
		{
			if(event.target.files[0].size > 5242880 || imageTypes.indexOf(event.target.files[0].type) < 0)
			{
				setSaveError
				(
					<>
						Sólo se permiten imágenes con las siguientes características:
						<br />
						&emsp;Tamaño &lt;= 5MB, &nbsp;Formato: [BMP, GIF, JPEG, PNG, WEBP]
					</>
				)

				setUIStatus(UIState.ERROR)

				if(imageRef != null && imageRef.current != null)
				{
					imageRef.current.value = ""
				}
			}
			else
			{
				let employee : any   = {...editableEmployee, image : event.target.files[0]}
				fileReader           = new FileReader()
				fileReader.onloadend = (event : any)=>
				{
					setEditableEmployee({...employee, imagePreview : fileReader.result})

					if(imageRef != null && imageRef.current != null)
					{
						imageRef.current.value = ""
					}
				}

				fileReader.readAsDataURL(event.target.files[0])
			}
		}
	}

	const imageDisposal = ()=>
	{
		if(imageRef != null && imageRef.current != null)
		{
			imageRef.current.value = ""
		}

		setEditableEmployee
		({
			...editableEmployee, image : null,
			imagePreview               : null,
		})
	}

	const updateRoleCampuses = (index : number, role : any)=>
	{
		let roles        = editableEmployee.roles
		let passingRoles = 0

		roles.splice(index, 1, role)
		setEditableEmployee
		({
			...editableEmployee, roles : roles.map
			(
				(roleItem : any)=>
				{
					if(roleItem.valid)
					{
						passingRoles++
					}

					return roleItem
				}
			)
		})

		setValidRoles(passingRoles)
	}

	const handleRegistryChange = (event : any)=>
	{
		if(event && event.target)
		{
			setEditableEmployee({...editableEmployee, [event.target.name] : event.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	const editEmployee = ()=>
	{
		setValidRoles(selectedEmployee.roles.length)

		setEditableEmployee
		({
			...selectedEmployee, password : "",
			nationality_id  : selectedEmployee.nationality_id || campusRef[currentUserCampus].company.country_id,
			country_id  : selectedEmployee.country_id || campusRef[currentUserCampus].company.country_id,
			identification_type_id : selectedEmployee.identification_type_id || basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].preferred_identification_type_id
		})
	}

	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					const roleRetrievalError : string = "El catálogo de roles no pudo ser extraído"

					dispatch(enableIdleLockSwitch())

					try
					{
						const result = await RolesService.searchRoles()

						if(result.status == 200 || result.status == 204)
						{
							setRoles
							(
								result.data.map
								(
									(role : any, index : number)=>
									({
										...role, campuses : [],
										valid    : true
									})
								)
							)
							setInitSetup(ROLES)
						}
						else
						{
							procedureComplaint(roleRetrievalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(roleRetrievalError)
						dispatch(disableIdleLockSwitch())
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case ROLES:
						if(roles.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay roles registrados, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const degreeRetrievalError : string = "Los grados académicos no pudieron ser extraídos"

							try
							{
								const expand = true
								const result = await CatalogService.getAcademicDegrees(sessionToken)

								if(result.status == 200 || result.status == 204)
								{
									setAcademicDegrees(result.data)
									setInitSetup(ACADEMIC_DEGREES)
								}
								else
								{
									procedureComplaint(degreeRetrievalError)
								}
							}
							catch(error)
							{
								procedureComplaint(degreeRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case ACADEMIC_DEGREES:
						if(academicDegrees.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay grados académicos en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							setNewEmployee
							({
								country_id             : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
								nationality_id         : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
								status_id              : employeeStatus.ACTIVE,
								statate_id             : employeeStatus.ACTIVE,
								gender_id              : "",
								identification_type_id : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].preferred_identification_type_id,
								birthday               : null,
								birthdayValue          : "",
								tags                   : [],
								roles                  : [],
								identification_code    : "",
								id_clase_365           : "",
								city                   : [],
								address                : "",
								zipcode                : "",
								first_name             : "",
								last_name              : "",
								observations           : "",
								password               : "",
								phone1                 : "",
								phone2                 : "",
								email                  : "",
								facebook               : "",
								instagram              : ""
							})

							search()
						}
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(() => setTotalRecords(data.length), [data])

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setSectionLoaded(false)
				setNewEmployee
				({
					...newEmployee, country_id : campusRef[currentUserCampus].company.country_id,
					nationality_id : campusRef[currentUserCampus].company.country_id,
					identification_type_id : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].preferred_identification_type_id
				})
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				search()
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			if(moduleReady)
			{
				if(basicInfo.countries.length < 1)
				{
					procedureComplaint("No hay países registrados, por lo que el acceso a este módulo permanecerá deshabilitado")
				}
				else
				{
					search()
				}
			}
		},
		[moduleReady]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError = "La búsqueda no pudo ser completada"

		try
		{
			dispatch(enableIdleLockSwitch())

			if(extendedFilterShown)
			{
				setExtendedFilterShown(false)
			}

			const result = await EmployeesService.searchEmployees
				(customSearch, isNaN(page) ? 1 : (page || 1))

			const minimumYear : number = result.data.date.split("-")[0] - 168

			if(result.status == 200 || result.status == 204)
			{
				setPagination(pageGrouping(result.data.current_page, result.data.last_page))
				setCurrentDate(result.data.date)
				setMinYear(minimumYear)
				setYearsToAlmostPresent([...Array(150)].map((_, i) => (minimumYear) + i))

				let normalData       : any = []
				let disabledData     : any = []
				let date             : any
				let dataToPush       : any

				result.data.data.map
				(
					(record : any, index : number)=>
					{
						date = record.birthday == null ? undefined : record.birthday.replaceAll("-", "/")

						dataToPush=
						{
							...record, birthday : date || "",
							status_id           : !record.bool_enabled ? 0 : record.employee.status_id,
							birthdayValue       : date == null ? "" : new Date(date + " 23:00:00")
						}

						if(record.id == currentUser.id)
						{
							disabledData.push(dataToPush)
						}
						else
						{
							if(dataToPush.state_id == null)
							{
								dataToPush=
								{
									...dataToPush, city : null,
									state_id            : ""
								}
							}
							else
							{
								dataToPush.state_name = ""

								if(dataToPush.city_id == null)
								{
									dataToPush.city = null
								}
								else
								{
									dataToPush=
									{
										...dataToPush, city : {...dataToPush.city, label : dataToPush.city.name},
										city_name           : ""
									}
								}
							}

							//adding boolean data type to toggle checking used by batch operations (multiple updating/deletion)--------------------------
							normalData.push
							({
								selected : false,
								data     : {...dataToPush}
							})
						}
					}
				)

				setReadOnlyData(disabledData)
				setData(normalData)

				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value}
					}

					return record
				}
			)
		)
	}

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected};
				}
			)
		)

		setSelectedRecords(totalRecords * (+selected))
	}

	const closePrompt = ()=>
	{
		setSelectedEmployee(null)
		setEditableEmployee(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError: string   = "El comando no pudo ser procesado"
					const recordIds   : number[] = !isNaN(recordId) ? [recordId] : data.filter((item : any) => item.selected)
						.map((item : any) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await EmployeesService.removeEmployees(recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	const roleCampusesUpdate = (role : any, index : number, tags : any)=>
	{
		updateRoleCampuses
		(
			index,
			{
				...role,valid : tags.length > 0,
				 campuses     : tags
			}
		)
	}

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  pagination={pagination}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  searchFormRef={searchFormRef}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  extendedFilterShown={extendedFilterShown}
		  searchIcon={<CustomIcon name="employee" />}
		  clearSearchRequested={clearSearchRequested}
		  addingPromptFn={() => showPrompt(newEmployee)}
		  setExtendedFilterShown={setExtendedFilterShown}
		  generalPlaceHolder={"Nombre, teléfono, observaciones, etc."}
		  extendedFilterViewClosing={() => setExtendedFilterShown(false)}
		  filterSummary=
		  {
			[
				customSearch.statusId < 0 ? null :
				{
					label   : "Estatus",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{basicInfo.employeeStatusRef["ES_" + customSearch.statusId].name}
					</p>
				},
				customSearch.tags.length < 1 ? null : 
				{
					label   : "Intereses",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.tags.map
						(
							(tag : any) => <div key={"tf_" + tag.id} className="badge rounded-pill bg-primary">
								{tag.name}
							</div>
						)
					}</div>
				},
			].filter((setting : any) => setting)
		  }
		  advancedSearchForm=
		  {<div className="container"><div className="row">
			<div className="col-sm-6"><fieldset>
				<legend><label style={{position : "relative", top : "4px"}}>
					Estatus
				</label></legend>
				<button className="form-control btn btn-default" disabled={idleLocked || customSearch.statusId < 0}
				  type="button" onClick={() => setCustomSearch({...customSearch, statusId : -1})} style={{textAlign : "left"}}
				>
					<FontAwesomeIcon icon={customSearch.statusId < 0 ? solid("circle-dot") : solid("circle")} />
					<span style={{paddingLeft : "5px"}}>
						Mostrar todo
					</span>
				</button>
				{
					Object.values(employeeStatuses).map
					(
						(status : any) => <button onClick={() => setCustomSearch({...customSearch, statusId : status.id})}
						  disabled={idleLocked || customSearch.statusId == status.id} className="form-control btn btn-default"
						  type="button" key={"S_" + status.id} style={{textAlign : "left"}}
						>
							<FontAwesomeIcon icon={customSearch.statusId == status.id ? solid("circle-dot") : solid("circle")} />
							<span style={{paddingLeft : "5px"}}>
								{status.name}
							</span>
						</button>
					)
				}
			</fieldset></div>
			<div className="col-sm-6"><fieldset>
				<legend><label style={{position : "relative", top : "4px"}}>
					Intereses
				</label></legend>
				<Multiselect onSelect={(tags) => setCustomSearch({...customSearch, tags : tags})} displayValue="name"
				  onRemove={(tags) => setCustomSearch({...customSearch, tags : tags})} placeholder="Seleccione" disable={idleLocked}
				  emptyRecordMsg="No hay más opciones" options={basicInfo.tags} selectedValues={customSearch.tags}
				/>
			</fieldset></div>
		  </div></div>}
		>
			{
				Object.keys(warningReport).map
				(
					(code : string) => <div className="callout callout-warning" key={"warn_" + code}
					  style={{padding : "unset"}}
					><table style={{width : "100%"}}><tbody><tr>
						<td style={{padding : "16px", verticalAlign : "middle"}}>
							<FontAwesomeIcon icon={solid("triangle-exclamation")} />
						</td>
						<td style={{width : "100%"}}>
							{warningReport[code]}
						</td>
						<td style={{verticalAlign : "top"}}><button className="btn btn-default" style={{padding : "unset"}}
						  type="button" onClick=
						  {
							()=>
							{
								let warn = warningReport
									delete warn[code]
								listWarnings({...warn})
							}
						  }
						>
							<FontAwesomeIcon icon={solid("times")} style={{margin : "4px"}} />
						</button></td>
					</tr></tbody></table></div>
				)
			}
			{
				(data.length < 1 && readOnlyData.length < 1)
					?
				<>
					<hr />
					<div style={{padding : "25px"}}>
						{Constants.noCampusSearchResults}
					</div>
					<hr />
				</>
					:
				<table className="commonTable">
					<thead><tr key="usersHeader">
						{
							data.length < 1 ? "" : <th><button className="btn btn-default" onClick={toggleSelectAll}
							  disabled={idleLocked || saveSuccess} type="button"
							>
								{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
							</button></th>
						}
						<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
							Id
						</th>
						<th>
							Nombre(s)
						</th>
						<th>
							Apellidos
						</th>
						<th>
							Teléfonos
						</th>
						<th>
							Correo
						</th>
						<th>
							Roles
						</th>
						<th>
							Intereses
						</th>
						<th>
							Estatus
						</th>
						<th />
					</tr></thead>
					<tbody>
						{
							readOnlyData.map
							(
								(record : any, index : number) => <tr key={"user" + index}>
									{data.length < 1 ? "" : <td />}
									<td ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}>
										{record.id}
									</td>
									<td>
										{record.first_name || "-"}
									</td>
									<td>
										{record.last_name || "-"}
									</td>
									<td>
										{[record.phone1, record.phone2].filter((phone : string) => isNotEmpty(phone)).join(", ") || "-"}
									</td>
									<td>
										{record.email}
									</td>
									<td>{
										record.roles && record.roles.filter((item : any)=>campusRef["C_" + item.pivot.campus_id]).map
										(
											(item : any, index : number) => <div key={"role_" + item.id + "_" + item.pivot.campus_id}
											  style={{width : "max-content"}}>{
												item.name + " " +
													(campusRef["C_" + item.pivot.campus_id].name || "")
											}</div>
										)
									}</td>
									<td>{
										isEmpty(record.tags) ? "-" : record.tags.map
										(
											(item : any, index : number) => <div className="badge rounded-pill bg-primary" key={"crc_" + item.id}>
												{item.name}
											</div>
										)
									}</td>
									<td>
										{basicInfo.employeeStatusRef["ES_" + record.status_id].name}
									</td>
									<td><div style={{display : "flex", justifyContent : "flex-end"}}><OverlayTrigger
									  overlay={<Tooltip id="tooltip-activos">Ver Detalle</Tooltip>}><button
									  className="button btn btn-default" type="button" disabled={idleLocked} onClick=
									  {
										()=>showPrompt
										({
											...record, phone1 : record.phone1 || "",
											phone2            : record.phone2 || "",
											observations      : record.employee.observations || "",
											campus_id         : record.employee.campus.id,
											identity_code     : record.employee.identity_code,
											id_clase_365      : record.id_clase_365 || "",
											zipcode           : record.zipcode || "",
											bool_read_only    : 1
										})
									  } style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
									>
										<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
									</button></OverlayTrigger></div></td>
								</tr>
							)
						}
						{
							data.map
							(
								(record : any, index : number) => <tr key={"user" + index}>
									<td><button className="btn btn-default" onClick={() => toggleRecordSelection(index)} type="button"
									  disabled={idleLocked}
									>
										{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
									</button></td>
									<td>
										{record.data.id}
									</td>
									<td>
										{record.data.first_name || "-"}
									</td>
									<td>
										{record.data.last_name || "-"}
									</td>
									<td>
										{[record.data.phone1, record.data.phone2].filter((phone : string) => isNotEmpty(phone)).join(", ") || "-"}
									</td>
									<td>
										{record.data.email}
									</td>
									<td>{
										record.data.roles && record
											.data
											.roles
											.filter((item : any)=>campusRef["C_" + item.pivot.campus_id])
											.map
											(
												(item : any, index : number)=> <div style={{width : "max-content"}}
												  key={"role_" + item.id + "_" + item.pivot.campus_id}
												>
													<b>
														{item.name}
														&nbsp;
														|
													</b>
													&nbsp;
													{(campusRef["C_" + item.pivot.campus_id].name || "")}
												</div>
											)
									}</td>
									<td>{
										isEmpty(record.data.tags) ? "-" : record.data.tags.map
										(
											(item : any, index : number) => <div className="badge rounded-pill bg-primary"
											  key={"crc_" + item.id}
											>
												{item.name}
											</div>
										)
									}</td>
									<td>
										{basicInfo.employeeStatusRef["ES_" + record.data.status_id].name}
									</td>
									<td>
										<div style={{display : "flex", justifyContent: "flex-end"}}>
										{
											record.data.student ? "" : <OverlayTrigger
											  overlay={<Tooltip id="tooltip-nuevo-alumno">Enlistar como alumno</Tooltip>}
											>
												<button type="button" className="button btn btn-default" disabled={idleLocked}
													style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
													onClick=
													{
														() => setSelectedStudent
														({
															...record.data, id : null,
															contacts           : [],
															enrollments        : [],
															imagePreview       : record.data.image,
															academic_degree_id : academicDegrees[0].id,
															phone1             : record.data.phone1 || "",
															phone2             : record.data.phone2 || "",
															id_clase_365       : record.data.id_clase_365 || "",
															zipcode            : record.data.zipcode || "",
															observations       : record.data.employee.observations || ""
														})
													}
												>
													<CustomIcon name="addStudent" />
												</button>
											</OverlayTrigger>
										}
										<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Ver Detalle</Tooltip>}><button
										  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
										  type="button" className="button btn btn-default" disabled={idleLocked} onClick=
										  {
												()=>showPrompt
												({
													...record.data, phone1 : record.data.phone1 || "",
													phone2                 : record.data.phone2 || "",
													id_clase_365           : record.data.id_clase_365 || "",
													zipcode                : record.data.zipcode || "",
													observations           : record.data.employee.observations || ""
												})
										  }
										>
											<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
											</button></OverlayTrigger>
											<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Borrar</Tooltip>}><button
											className="button btn btn-default" style={{display : "table-column"}} type="button"
											onClick={() => confirmRemoval(record.data.id)} disabled={idleLocked}
											>
												<CustomIcon name="bTrash" />
											</button></OverlayTrigger>
										</div>
									</td>
								</tr>
							)
						}
					</tbody>
				</table>
			}
		</SectionContainer>
		{
			selectedEmployee == null ? "" : <EmployeeInfoModal closeFn={setSelectedEmployee} afterCloseFn={search}
			  academicDegrees={academicDegrees} selectedEmployee={selectedEmployee} allowedUpdate={allowed.update}
			  roles={roles} date={currentDate}
			/>
		}
		{
			selectedStudent == null ? "" : <StudentInfoModal selectedStudent={selectedStudent} allowedUpdate={allowed.update}
			  academicDegrees={academicDegrees} closeFn={setSelectedStudent} afterCloseFn={search} date={currentDate}
			/>
		}
	</>
}

export default Employees
