//imports
import UnderlineHeading from "../../../../components/underlineHeading";
import CommonTable from "../../../../components/commonTable";
import Card from "../../../../components/card";
import FiltersController from "./filters/filtersController";
import AttendancesModalController from "./modal/attendancesModalController";
import { AttendancesViewProps } from "./interfaces";
import MiniModalController from "../../../../components/modals/miniModal/miniModalController";
import PaginationComponent from "../../../../components/pagination/pagination";
import Constants from "../../../../constants";

//styles
import "./styles.scss";

const AttendancesView = ({
	sectionLoaded,
	schedules,
	setSchedules,
	selectedSchedule,
	setSelectedSchedule,
	displayError,
	columns,
	itemsPerPage,
	handlePageSizeChange,
	pages,
	pagesSize,
	itemCount,
	showInfoModal,
	dataInfoModal,
	closeInfoModal,
	miniMessage,
	scheduleClick
}: AttendancesViewProps) => {

	return (
		<>
			{
				!sectionLoaded ? ""
				:
				<div className="attendancesContainer">
					<h2 className="pageHeader">
						Asistencias
					</h2>
					<UnderlineHeading/>
					<FiltersController
						displayError = {displayError}
						setSchedules = {setSchedules}
						itemsPerPage = {itemsPerPage}
					/>
					{
						(schedules == null || schedules.length < 1)
						?
						<>
							<hr />
							<div className="noResult">
								{Constants.Common.noResults}
							</div>
							<hr />
						</>
						:
						<>
							<Card>
								<div className="containerTable col-lg-12">
									<CommonTable
										columns = {columns}
										data = {schedules}
										onRowClick={scheduleClick}>
									</CommonTable>
								</div>
							</Card>
							<br/>
							{
								selectedSchedule?
								<AttendancesModalController
									selectedSchedule = {selectedSchedule}
									setSelectedSchedule = {setSelectedSchedule}
									miniMessage = {miniMessage}
								/>
								:
								""
							}
							<MiniModalController
								title={dataInfoModal.title? dataInfoModal.title : ""}
								show={showInfoModal}
								onClose={closeInfoModal}
								>
								{
									dataInfoModal.content
								}
							</MiniModalController>
						</>
					}
					<div className="attendancesPagination">
						<PaginationComponent
							pages={pages}
							onPageSizeChange={handlePageSizeChange}
							pageSizeVisible={true}
							pageSizes={pagesSize}
							itemCount={itemCount}
							style={{paddingBottom: 0}}
						/>
					</div>
				</div>
			}
		</>
	);
}

export default AttendancesView