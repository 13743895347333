import { AxiosResponse } from "axios";
import { axiosInstance } from "./axiosInstance";
import { AttendanceStudentDTO } from "../../models/AttendanceStudentDTO";
import { ExtraStudentDTO } from "../../models/ExtraStudentDTO";
import { AttendanceHistoryDTO } from "../../models/AttendanceHistoryDTO";

const RESOURCE_URL_V2 = "/admin/campus/";

export class AttendanceService {

	getExtraStudentsByClass = async (campusId: number, scheduleId: number, classId: number): Promise<AxiosResponse<ExtraStudentDTO[]>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/schedules/${scheduleId}/classes/${classId}/attendances/_extra`;
		const response = await axiosInstance.get<ExtraStudentDTO[]>(uri);
		return response;
	}

	getSessionAttendances = async (campusId: number, scheduleId: number, classId: number): Promise<AxiosResponse<AttendanceStudentDTO[]>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/schedules/${scheduleId}/classes/${classId}/attendances`;
		const response = await axiosInstance.get<AttendanceStudentDTO[]>(uri);
		return response;
	}

	getAttendancesHistory = async (campusId: number, scheduleId: number): Promise<AxiosResponse<AttendanceHistoryDTO[]>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/schedules/${scheduleId}/attendances/_history`;
		const response = await axiosInstance.get<AttendanceHistoryDTO[]>(uri);
		return response;
	}

	registerAttendanceClass = async (campusId: number, scheduleId: number, classId: number,  payload: any): Promise<AxiosResponse> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/schedules/${scheduleId}/attendances/${classId}`;

        const formData = new FormData();
        formData.append("attendances", JSON.stringify(payload.attendances));
        formData.append("extraStudentsListing", JSON.stringify(payload.extraStudentsListing));
        payload.attachedFiles.forEach((file: File, index: number) => {
            formData.append(`attachedFiles[${index}]`, file);
        });

		const response = await axiosInstance.post(uri, formData);
		return response;
	}

	updateAttendanceClass = async (campusId: number, scheduleId: number, classId: number, payload: any): Promise<AxiosResponse> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/schedules/${scheduleId}/attendances/${classId}/_update`;
        
        const formData = new FormData();
        formData.append("attendances", JSON.stringify(payload.attendances));
        formData.append("extraStudentsListing", JSON.stringify(payload.extraStudentsListing));
        payload.attachedFiles.forEach((file: File, index: number) => {
            formData.append(`attachedFiles[${index}]`, file);
        });

		const response = await axiosInstance.post(uri, formData);
		return response;
	}

    getClassDocumentById = async (campusId: number, scheduleId: number, classId: number, documentId: number): Promise<AxiosResponse> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/schedules/${scheduleId}/classes/${classId}/attendances/${documentId}/_file`;
		const response = await axiosInstance.get(uri,
            {
                responseType: 'blob', // Importante para manejar archivos                
            }
        );
		return response;
	}
}