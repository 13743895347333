//imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { MiniModalViewProps } from "./interfaces";

//styles
import "./styles.scss";

const MiniModalView = ({
    title,
    setIsOpen,
    onClose,
    idleLocked,
    children
}: MiniModalViewProps) => {

    return (
        <div className="miniModal flexCenter">
            <div className="miniModalContainer">
                <div className="miniModalHeader">
                    <h5 className="title">
                        {title}
                    </h5>
                    <div className="close">
                        <button
                            className="closeBtn flexCenter"
                            disabled={idleLocked}
                            onClick={() => {
                                setIsOpen(false);
                                onClose();
                            }}>
                            <FontAwesomeIcon className="icon" size="3x" icon={solid("close")} />
                        </button>
                    </div>
                </div>
                <div className="miniModalBody">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default MiniModalView