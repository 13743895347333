import React,
{
    useEffect,
    useState
} from "react";

import {
    useNavigate
} from "react-router-dom";

import {
    useDispatch,
    useSelector
} from "react-redux";

import Constants,
{
    ENROLLMENT_STATUS,
    FINANCES_MODALS,
    invoiceStatus,
    owedArticleStatus,
    owedTuitionStatus,
    PERMISSIONS_CODES,
    TRANSFER_MODAL_CONTENT,
    TUITION_PROGRESS_STATUS
} from "../../../../constants";

import {
    RootState,
    enableIdleLockSwitch,
    disableIdleLockSwitch,
    displayNotice,
    AppDispatch
} from "../../../../globals";

import {
    getErrorMessage
} from "../../../../common";

import HttpManager from "../../../../services/HttpManager";

import {
    handleArticlesSelection,
    handleTuitionsSelection,
    setCheckedArticles,
    setDisabledTuitions,
    setGeneralData,
    setIsCancelPayment,
    setIsNewInvoice,
    setIsPartialPayment,
    setModalContent,
    setRefundItems,
    setSelectedArticles,
    setSelectedCourses,
    setSelectedCredit,
    setSelectedInvoice,
    setSelectedPayment,
    setSelectedTuitions,
    setShowModal,
    setShowTransferCreditModal,
    setTotalCheckoutItems,
    setTransferModalContent
} from "../../../../redux/reducers/accountStatementReducer";
import { setPermissions, UserState } from "../../../../redux/reducers/sessionReducer";
import { setPaymentMethods } from "../../../../redux/reducers/catalogReducer";
import { handleSelectedPayment } from "../../../../redux/reducers/paymentsReducer";

import CustomIcon from "../../../../components/customIcon";
import { Column } from "../../../../components/commonTable";
import ButtonComponent from "../../../../components/buttons/button";
import AccountStatementView from "./accountStatementView";

import "./helper.css";
import { handleSelectedInvoice } from "../../../../redux/reducers/invoicesReducer";
import { ArticleDTO } from "../../../../models/ArticleDTO";
import { TuitionDTO } from "../../../../models/TuitionDTO";
import { StudentDTO } from "../../../../models/StudentDTO";
import { handleSelectedCredit } from "../../../../redux/reducers/creditsReducer";

const AccountStatementController = (props: any, route: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const http = HttpManager.getInstance();
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const permissions: any[] = useSelector((state: RootState) => state.rootReducer.sessionState.permissions);
    const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
    const currentRole: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentRole);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const studentData: StudentDTO = useSelector((state: RootState) => state.rootReducer.accountStatement.studentData);
    const checkedArticles: any = useSelector((state: RootState) => state.rootReducer.accountStatement.checkedArticles);
    const selectedCourses: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedCourses);
    const selectedArticles: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedArticles);
    const selectedTuitions: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedTuitions);
    const disabledTuitions: any = useSelector((state: RootState) => state.rootReducer.accountStatement.disabledTuitions);
    const selectedInvoice: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedInvoice);
    const selectedPayment: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedPayment);
    const isPartialPayment: boolean = useSelector((state: RootState) => state.rootReducer.accountStatement.isPartialPayment);
    const isNewInvoice: boolean = useSelector((state: RootState) => state.rootReducer.accountStatement.isNewInvoice);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [checkoutData, setCheckoutData] = useState<any>({
        courses: [],
        articles: []
    });

    const tuitionColumns: Column[] = [
        {
            id: "selectTuition",
            header: "",
            accessor: "",
            render: (data: TuitionDTO) => data.statusId === 2 ? <></>
                : <button className="btn btn-default" type="button"
                    disabled={idleLocked}
                >
                    <CustomIcon name="bCheck" />
                </button>,
            overrideCellStyle: () => ({
                borderBottom: 0
            })
        },
        {
            id: "id",
            header: "",
            accessor: "id",
            overrideCellStyle: () => ({
                borderBottom: 0
            })
        },
        {
            id: "monthNumber",
            header: Constants.Common.concept,
            accessor: "monthNumber"
        },
        {
            id: "dueDate",
            header: Constants.Common.endDate,
            accessor: "dueDate"
        },
        {
            id: "billingStatus",
            header: Constants.Common.billingStatus,
            accessor: "",
            render: (data: TuitionDTO) => <span
                style={{
                    color: data.isExpired && (
                        data.statusId === owedTuitionStatus.PENDING
                        || data.statusId === owedTuitionStatus.PARTLY_PAID
                    )
                        ? Constants.Styles.text.colors.red
                        : Constants.Styles.text.colors.blackPrimary,
                    font: Constants.Styles.text.fonts.robotoBold16,
                    marginBottom: 0
                }}>
                {
                    data.isExpired && (
                        data.statusId === owedTuitionStatus.PENDING
                        || data.statusId === owedTuitionStatus.PARTLY_PAID
                    ) ? `Vencida/${data.status}` : data.status
                }
            </span>
        },
        {
            id: "progressStatus",
            header: Constants.Common.progressStatus,
            accessor: "",
            render: (data: TuitionDTO) => <span
                style={{
                    marginBottom: 0,
                    font: Constants.Styles.text.fonts.robotoBold16,
                    color: data.progressStatusId === TUITION_PROGRESS_STATUS.NO_COMPLETED
                        ? Constants.Styles.text.colors.red
                        : Constants.Styles.text.colors.blackPrimary,
                }
                }>
                {data.progressStatus}
            </span>,
            overrideCellStyle: () => ({
                textAlign: "center"
            })
        },
        {
            id: "totalAmount",
            header: Constants.Common.totalAmount,
            accessor: "",
            render: (data: TuitionDTO) => data.subTotal.moneyFormat,
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        },
        {
            id: "paidAmount",
            header: Constants.Common.paidAmount,
            accessor: "",
            render: (data: TuitionDTO) => data.paidAmount.moneyFormat,
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        },
        {
            id: "pendingAmount",
            header: Constants.Common.pendingAmount,
            accessor: "",
            render: (data: TuitionDTO) => data.pendingAmount.moneyFormat,
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        },
        {
            id: "invoice",
            header: Constants.Common.invoice,
            accessor: "",
            render: (data: TuitionDTO) => data.invoiceId > 0
                ? <ButtonComponent className="wa-btn sm link"
                    tooltip={Constants.Common.invoiceDetail}
                    style={{ opacity: idleLocked ? 0.5 : 1 }}
                    disabled={idleLocked}
                    onClick={() => getInvoiceById(data.invoiceId)}
                >
                    {data.invoiceId.toString()}
                </ButtonComponent>
                : "-",
            renderSubRow: (data: TuitionDTO) => {
                return data.invoiceId === 0
                    && data.statusId !== invoiceStatus.FULLY_PAID
                    && !data.canBePaid ? (
                    <div style={{
                        color: Constants.Styles.text.colors.red,
                        textAlign: "center"
                    }}>
                        {data.cannotBePaidReason}
                    </div>
                ) : null;
            },
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        }
    ];

    const articleColumns: Column[] = [
        {
            id: "selectArticle",
            header: "",
            accessor: "",
            render: (data: ArticleDTO) => data.statusId === owedArticleStatus.FULLY_PAID ? <></>
                : <button className="btn btn-default" type="button"
                    disabled={false}
                >
                    <CustomIcon name="bCheck" />
                </button>,
            overrideCellStyle: () => ({
                borderBottom: 0
            })
        },
        {
            id: "id",
            header: "",
            accessor: "id",
            overrideCellStyle: () => ({
                borderBottom: 0
            })
        },
        {
            id: "name",
            header: Constants.Common.concept,
            accessor: "name"
        },
        {
            id: "createdAt",
            header: Constants.Common.date,
            accessor: "createdAt"
        },
        {
            id: "status",
            header: Constants.Common.status,
            accessor: "",
            render: (data: ArticleDTO) => <span
                style={{
                    color: Constants.Styles.text.colors.blackPrimary,
                    font: Constants.Styles.text.fonts.robotoBold16,
                    marginBottom: 0
                }}>
                {data.status}
            </span>
        },
        {
            id: "totalAmount",
            header: Constants.Common.totalAmount,
            accessor: "",
            render: (data: ArticleDTO) => data.subTotal.moneyFormat,
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        },
        {
            id: "paidAmount",
            header: Constants.Common.paidAmount,
            accessor: "",
            render: (data: ArticleDTO) => data.paidAmount.moneyFormat,
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        },
        {
            id: "pendingAmount",
            header: Constants.Common.pendingAmount,
            accessor: "",
            render: (data: ArticleDTO) => data.pendingAmount.moneyFormat,
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        },
        {
            id: "invoice",
            header: Constants.Common.invoice,
            accessor: "",
            render: (data: ArticleDTO) => data.invoiceId > 0
                ?
                <ButtonComponent
                    className="wa-btn sm link"
                    style={{ opacity: idleLocked ? 0.5 : 1 }}
                    tooltip={Constants.Common.invoiceDetail}
                    disabled={idleLocked}
                    onClick={() => getInvoiceById(data.invoiceId)}
                >
                    {data.invoiceId.toString()}
                </ButtonComponent>
                : "-",
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        }
    ];

    const resetStateData = () => {
        dispatch(setSelectedCourses([]));
        dispatch(setSelectedTuitions(new Set()));
        dispatch(setDisabledTuitions(new Set()));
        dispatch(setCheckedArticles(new Set()));
        dispatch(setSelectedArticles([]));
        dispatch(setRefundItems(new Set()));
        dispatch(setIsPartialPayment(false));
        setSelectedInvoice(null);
    }

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{
                color: "#FF0000",
                display: "inline-block"
            }}>
                Error
            </h3>
        }));
    }

    const toggleArticleSelection = (article: any) => {
        dispatch(handleArticlesSelection(article));
    }

    const toggleTuitionsSelection = (tuition: any) => {
        dispatch(handleTuitionsSelection(tuition));
    }

    const tuitionColumnsWithLogic = tuitionColumns.map((column: Column) => {
        if (column.id === "selectTuition" && column.render) {
            return {
                ...column,
                render: permissions.includes(PERMISSIONS_CODES.PAYMENT_PERMISSIONS.CREATE)
                    && permissions.includes(PERMISSIONS_CODES.INVOICE_PERMISSIONS.CREATE)
                    ? (tuition: TuitionDTO) => (
                        tuition.statusId === owedTuitionStatus.PENDING
                            && tuition.invoiceId === 0
                            ? <button
                                className="btn btn-default"
                                type="button"
                                disabled={idleLocked
                                    || !tuition.canBePaid
                                    || Array.from(disabledTuitions).includes(tuition.id)}
                                onClick={() => toggleTuitionsSelection(tuition)}
                            >
                                <CustomIcon
                                    name={Array.from(selectedTuitions).includes(tuition.id) ? "bCheck" : "bUncheck"}
                                />
                            </button>
                            : <React.Fragment />
                    )
                    : () => <React.Fragment />,
            };
        }
        return column;
    });

    const articleColumnsWithLogic = articleColumns.map((column: Column) => {
        if (column.id === "selectArticle" && column.render) {
            return {
                ...column,
                render: (article: ArticleDTO) => (
                    article.statusId === owedArticleStatus.PENDING && article.invoiceId === 0
                        ? <button
                            className="btn btn-default"
                            type="button"
                            disabled={idleLocked}
                            onClick={() => toggleArticleSelection(article)}
                        >
                            <CustomIcon name={Array.from(checkedArticles).includes(article.id) ? "bCheck" : "bUncheck"} />
                        </button>
                        : <></>
                ),
            };
        }
        return column;
    });

    const getStudentById = async () => {
        try {
            let response = await http.studentService.getStudentById(
                currentCampus.id,
                studentData.id
            );
            let student = response.data;
            return student;
        } catch (error) {
            console.log(error);
            onError(getErrorMessage(error));
        }
    }

    const getStudentCourses = async () => {
        try {
            let response = await http.financesService.getStudentCourses(
                currentCampus.id,
                studentData.id
            );
            let courses = response.data;
            return courses;
        } catch (error) {
            console.log(error);
            onError(getErrorMessage(error));
            return [];
        }
    }

    const getStudentArticles = async () => {
        try {
            let response = await http.financesService.getStudentArticles(
                currentCampus.id,
                studentData.id
            );
            let articles = response.data;
            return articles;
        } catch (error) {
            console.log(error);
            onError(getErrorMessage(error));
            return [];
        }
    }

    const getPaymentMethods = async () => {
        try {
            const response = await http.paymentMethodService
                .getPaymentMethodsByCountry(currentCampus.countryId);
            const paymentMethos = response.data;
            return paymentMethos;
        } catch (err: any) {
            console.error(err);
        }
    }

    const fetchData = async () => {
        resetStateData();
        const messageError: string = "No se pudo extraer la información financiera del alumno seleccionado"
        dispatch(enableIdleLockSwitch())
        if (currentCampus && userData) {
            try {
                const [
                    student,
                    courses,
                    articles,
                    paymentMethods
                ] = await Promise.all([
                    getStudentById(),
                    getStudentCourses(),
                    getStudentArticles(),
                    getPaymentMethods()
                ]);
                const coursesHistory = courses
                    .filter((course: any) => course.enrollmentStatusId !== ENROLLMENT_STATUS.ACTIVE);
                const activeCourses = courses
                    .filter((course: any) => course.enrollmentStatusId === ENROLLMENT_STATUS.ACTIVE);
                const articlesHistory = articles
                    .filter((article: any) => article.statusId === owedArticleStatus.DELIVERED);
                const activeArticles = articles
                    .filter((article: any) => article.statusId !== owedArticleStatus.DELIVERED)
                dispatch(setGeneralData({
                    student,
                    courses: activeCourses,
                    articles: activeArticles,
                    coursesHistory,
                    articlesHistory,
                    balance: student?.generalBalance,
                }));
                dispatch(disableIdleLockSwitch());
                dispatch(setPaymentMethods(paymentMethods));
                setIsLoaded(true);
            }
            catch (error: any) {
                dispatch(disableIdleLockSwitch());
                if (error.status === 404) {
                    navigate("/students");
                }
                else {
                    console.log(error);
                    onError(messageError);
                }
            }
        }
    }

    const getInvoiceById = async (invoiceId: number) => {
        try {
            dispatch(enableIdleLockSwitch());
            let response = await dispatch(handleSelectedInvoice({
                sessionToken: userData?.token ?? "",
                campusId: currentCampus.id,
                studentId: studentData.id,
                invoiceId
            })).unwrap();
            let invoice = response.data;
            dispatch(setSelectedInvoice(invoice));
            dispatch(setIsNewInvoice(false));
            dispatch(disableIdleLockSwitch());
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const showPaymentDetail = async (rowData: any) => {
        try {
            dispatch(enableIdleLockSwitch());
            const response = await dispatch(handleSelectedPayment({
                sessionToken: userData?.token ?? "",
                campusId: currentCampus.id,
                paymentId: rowData.id
            })).unwrap();
            dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL));

            const payment = {
                ...response.data,
                account: response.data.recipientAccount,
                amount: response.data.amount.value,
                files: response.data.documents
            };
            dispatch(setSelectedPayment(payment));
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const showCreditDetail = async (rowData: any) => {
        try {
            dispatch(enableIdleLockSwitch());
            let response = await dispatch(handleSelectedCredit({
                campusId: currentCampus.id,
                creditId: rowData.id
            })).unwrap();
            const credit = response.data;
            dispatch(setSelectedCredit(credit));
            dispatch(setModalContent(FINANCES_MODALS.APPLY_CREDIT_DETAILS));
            dispatch(setShowModal(true));
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const fetchRoleData = async () => {
        try {
            const response = await http.roleService.getRoleData(currentRole.id);
            dispatch(setPermissions(response.data.permissions));
            return response.data;
        } catch (err: any) {
            console.error(err);
        }
    }

    const showSuccessMessage = () => {
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }));
    }

    const cancelInvoice = () => {
        dispatch(setModalContent(FINANCES_MODALS.CANCEL_INVOICE));
    }

    const proccessPayment = () => {
        dispatch(setIsPartialPayment(true));
        dispatch(setIsNewInvoice(false));
        dispatch(setModalContent(FINANCES_MODALS.EMIT_PAYMENT));
    }

    const closeModalInvoiceDetail = () => {
        dispatch(setIsPartialPayment(false));
        dispatch(setShowModal(false));
        dispatch(setSelectedInvoice(null));
    }

    const handleCancelPayment = () => {
        setIsCancelPayment(true);
        dispatch(setModalContent(FINANCES_MODALS.CANCEL_PAYMENT))
    }

    const paymentDetailCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.INVOICE))
    }

    const showPaymentUpdateModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.UPDATE_PAYMENT));
    }

    const updatePaymentCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.INVOICE))
    }

    const onSuccessUpdatePayment = () => {
        dispatch(setModalContent(FINANCES_MODALS.UPDATE_PAYMENT));
        showSuccessMessage();
        showPaymentDetail(selectedPayment);
    }

    const cancelPaymentCloseModal = () => {
        setIsCancelPayment(false);
        dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL));
    }

    const onSuccessCancelPayment = () => {
        fetchData();
        dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL));
        showSuccessMessage();
    }

    const closeModalEmitPayment = () => {
        dispatch(setShowModal(false));
    }

    const cancelEmitPayment = () => {
        if (isPartialPayment && !isNewInvoice) {
            dispatch(setModalContent(FINANCES_MODALS.INVOICE));
        } else {
            dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
        }
    }

    const showApplyCreditModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.APPLY_CREDIT));
    }

    const cancelInvoiceCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.INVOICE));
    }

    const onSuccessCancelInvoice = () => {
        fetchData();
        dispatch(setShowModal(false));
        showSuccessMessage();
    }

    const goToProccessPayment = (isPartialPayment: boolean = false) => {
        dispatch(setIsPartialPayment(isPartialPayment));
        dispatch(setIsNewInvoice(true));
        dispatch(setModalContent(FINANCES_MODALS.EMIT_PAYMENT));
    }

    const closeModalCheckout = () => {
        dispatch(setShowModal(false));
    }

    const generateInvoiceCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
    }

    const onSuccessGeneratePaidInvoice = () => {
        fetchData();
        dispatch(setShowModal(false));
        showSuccessMessage();
    }

    const onSuccessEmitPayment = () => {
        fetchData();
        dispatch(setShowModal(false));
        showSuccessMessage();
    }

    const showCheckoutModal = () => {
        dispatch(setShowModal(true));
        dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
    }

    const onSuccessApplyCredit = () => {
        fetchData();
        dispatch(setShowModal(false));
        showSuccessMessage();
    }

    const cancelApplyCredit = () => {
        if (isPartialPayment && !isNewInvoice) {
            dispatch(setModalContent(FINANCES_MODALS.INVOICE));
        } else {
            dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
        }
    }

    const closeModalCancelApplyCredit = () => {
        dispatch(setShowModal(false));
    }

    const creditDetailCloseModal = () => {
        dispatch(setShowModal(false));
    }

    const onSuccessTransferCreditModal = () => {
        fetchData();
        dispatch(setShowTransferCreditModal(false));
        showSuccessMessage();
    }

    const showTransferCreditModal = () => {
        dispatch(setTransferModalContent(TRANSFER_MODAL_CONTENT.SELECT_STUDENT))
        dispatch(setShowTransferCreditModal(true));
    }

    const closeTransferCreditModal = () => {
        dispatch(setShowTransferCreditModal(false));
    }

    useEffect(() => {
        if (userData && userData.token) {
            if (currentCampus === null) {
                onError(
                    "No hay planteles registrados o asignados al rol en uso por el usuario, " +
                    "por lo que el acceso a este módulo permanecerá deshabilitado."
                )
            }
            else {
                fetchData();
            }
        }
    }, []);

    useEffect(() => {
        fetchRoleData();
    }, [currentRole]);

    useEffect(() => {
        if (isLoaded) {
            fetchData();
        }
    }, [isLoaded]);

    useEffect(() => {
        setCheckoutData({
            courses: selectedCourses,
            articles: selectedArticles
        });
        if (selectedCourses.length > 0 || selectedArticles.length > 0) {
            dispatch(setShowModal(true));
            dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
        } else {
            dispatch(setShowModal(false));
        }
        dispatch(setTotalCheckoutItems(selectedTuitions.size + selectedArticles.length));
    }, [selectedCourses, checkedArticles]);

    useEffect(() => {
        if (selectedInvoice && Object.keys(selectedInvoice).length > 0) {
            dispatch(setShowModal(true));
            dispatch(setModalContent(FINANCES_MODALS.INVOICE));
        }
    }, [selectedInvoice]);

    return !isLoaded || currentCampus === null || userData === null
        ? <React.Fragment></React.Fragment>
        : <AccountStatementView
            accountStatementProps={{
                showCheckoutModal
            }}
            tableProps={{
                tuitionColumns: tuitionColumnsWithLogic,
                articleColumns: articleColumnsWithLogic
            }}
            checkoutDataProps={{
                checkoutData,
                goToProccessPayment,
                closeModal: closeModalCheckout
            }}
            invoiceDetailProps={{
                cancelInvoice,
                showPaymentDetail,
                showCreditDetail,
                proccessPayment,
                closeModal: closeModalInvoiceDetail
            }}
            emitPaymentProps={{
                isNewInvoice,
                isPartialPayment,
                closeModal: closeModalEmitPayment,
                cancelEmitPayment,
                showApplyCreditModal,
                onSuccess: onSuccessEmitPayment
            }}
            paymentDetailProps={{
                handleCancelPayment,
                closeModal: paymentDetailCloseModal,
                showUpdatePayment: showPaymentUpdateModal
            }}
            paymentUpdateProps={{
                onSuccess: onSuccessUpdatePayment,
                closeModal: updatePaymentCloseModal
            }}
            cancelPaymentProps={{
                closeModal: cancelPaymentCloseModal,
                onSuccess: onSuccessCancelPayment
            }}
            cancelInvoiceProps={{
                closeModal: cancelInvoiceCloseModal,
                onSuccess: onSuccessCancelInvoice
            }}
            generateInvoiceProps={{
                onSuccess: onSuccessGeneratePaidInvoice,
                closeModal: generateInvoiceCloseModal
            }}
            applyCreditProps={{
                isNewInvoice,
                isPartialPayment,
                closeModal: closeModalCancelApplyCredit,
                cancelApplyCredit,
                onSuccess: onSuccessApplyCredit
            }}
            appliedCreditsDetailProps={{
                closeModal: creditDetailCloseModal,
            }}
            transferCreditProps={{
                showModal: showTransferCreditModal,
                onSuccess: onSuccessTransferCreditModal,
                closeModal: closeTransferCreditModal
            }}
        />
}

export default AccountStatementController;