import { CSSProperties } from "react";

export const styles = {
    container: {
        v: <CSSProperties> {
            paddingTop: "0px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
            overflow: "auto"
        },
        form:{
            input: {
                v: <CSSProperties> {
                    border: "1px solid #7678BF",
                    borderRadius: "5px",
                    minHeight: "30px",
                    padding: "5px"
                }
            },
            textarea: {
                v: <CSSProperties> {
                    resize: "vertical",
                    maxWidth: "100%",
                    border: "1px solid #7678BF",
                    borderRadius: "5px",
                    minHeight: "50px",
                    padding: "5px"
                }
            }
        },
        footer: {
            v: <CSSProperties> {
                display: "flex",
                width: "100%",
                position: "sticky",
                bottom: -1,
                marginBottom: 0,
                justifyContent: "center",
                backgroundColor: "white",
            }
        }
    }
}