import React, {
    useEffect,
    useState
} from "react";

import { useNavigate } from "react-router-dom";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import Constants,
{
    appliedCreditStatus,
    FILTER_TYPES,
    FINANCES_MODALS,
    OPERATORS,
} from "../../../../constants";
import {
    RootState,
    enableIdleLockSwitch,
    disableIdleLockSwitch,
    displayNotice,
    AppDispatch
} from "../../../../globals";
import HttpManager from "../../../../services/HttpManager";
import { setPermissions, UserState } from "../../../../redux/reducers/sessionReducer";
import {
    handleCreditsXlsx,
    handleSelectedCredit,
    setIsCancelPayment,
    setIsNewInvoice,
    setIsPartialPayment,
    setModalContent,
    setRefundItems,
    setSelectedCredit,
    setSelectedInvoice,
    setSelectedPayment,
    setShowModal,
    setStudentData
} from "../../../../redux/reducers/creditsReducer";
import { handleSelectedInvoice } from "../../../../redux/reducers/invoicesReducer";
import { Column } from "../../../../components/commonTable";
import CredistView from "./creditsView";
import ButtonComponent from "../../../../components/buttons/button";
import TruncateTextComponent from "../../../../components/truncateText";
import { handleSelectedPayment } from "../../../../redux/reducers/paymentsReducer";
import { getErrorMessage } from "../../../../common";
import { AppliedCreditDTO } from "../../../../models/AppliedCreditDTO";
import { styles } from "./style";

const CreditsController = (props: any, route: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const pagesSize: number[] = [10, 20, 50, 100];
    const http = HttpManager.getInstance();
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
    const currentRole: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentRole);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const studentData: any = useSelector((state: RootState) => state.rootReducer.credits.studentData);
    const selectedInvoice: any = useSelector((state: RootState) => state.rootReducer.credits.selectedInvoice);
    const selectedPayment: any = useSelector((state: RootState) => state.rootReducer.credits.selectedPayment);
    const isPartialPayment: boolean = useSelector((state: RootState) => state.rootReducer.credits.isPartialPayment);
    const isNewInvoice: boolean = useSelector((state: RootState) => state.rootReducer.credits.isNewInvoice);
    const appliedCreditStatuses: [] = useSelector((state: RootState) => state.rootReducer.catalog.appliedCreditStatuses);
    const APPLIED_CREDIT_TYPES: [] = useSelector((state: RootState) => state.rootReducer.catalog.APPLIED_CREDIT_TYPES);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [appliedCredits, setAppliedCredits] = useState<any>(null);
    const [pages, setPages] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(pagesSize[0]);
    const [itemCount, setItemCount] = useState<any>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const [filters, setFilters] = useState<{ [key: string]: any }>({});
    const [createdAtRange, setCreatedAtRange] = useState<{ start: Date | null; end: Date | null }>({
        start: null,
        end: null,
    });
    const [amount, setAmount] = useState<{ option: string; quantity: number }>({
        option: "",
        quantity: 0,
    });

    const onChangeSearch = (newSearchValue: string) => {
        setSearchValue(newSearchValue);
    }

    const onClickSearch = () => {
        setFilters({ ...filters, search: searchValue });
    }

    const onClearSearch = () => {
        setSearchValue("");
        setFilters({});
    }

    const onClearFilters = () => {
        setSearchValue("");
        setFilters({});
    }

    const updateFilters = (prevFilters: any, columnId: string, value: any) => {
        const updatedFilters = { ...prevFilters };

        if (columnId === "createdAt") {
            const { start, end } = value || {};
            if (value === null) {
                setCreatedAtRange({ start: null, end: null });
                delete updatedFilters[columnId];
                return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
            }
            if (!start || !end || start > end) {
                return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
            }
        }

        const handleQuantityFilter = (column: string) => {
            const { option, quantity } = value || {};
            if (!option || quantity < 0) {
                delete updatedFilters[column];
                if (column === "amount") setAmount({ option: "", quantity: 0 });
            }
        };

        if (["amount"].includes(columnId)) {
            handleQuantityFilter(columnId);
        }

        if (value === null) {
            if (updatedFilters[columnId]) {
                delete updatedFilters[columnId];
            }
        } else {
            updatedFilters[columnId] = value;
        }

        if (Array.isArray(value)) {
            if (value.length === 0) {
                delete updatedFilters[columnId];
            } else {
                updatedFilters[columnId] = value;
            }
        }

        return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
    };

    const onFilterChange = (columnId: string, value: any) => {
        setFilters((prevFilters) => {
            const newFilters = updateFilters(prevFilters, columnId, value);
            return newFilters;
        });
    };

    const creditsColumn: Column[] = [
        {
            id: "id",
            header: Constants.Common.id,
            accessor: "id",
            render: (credit: any) => credit.id > 0
                ? <ButtonComponent className="wa-btn sm link"
                    tooltip={Constants.Common.creditDetail}
                    style={{ opacity: idleLocked ? 0.5 : 1 }}
                    disabled={idleLocked}
                    onClick={() => showCreditDetail(credit)}
                >
                    {credit.id.toString()}
                </ButtonComponent>
                : "-",
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            filterType: FILTER_TYPES.INPUT_NUMBER,
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "100px"
            })
        },
        {
            id: "ownerCorrelative",
            header: Constants.Common.correlative,
            accessor: "ownerCorrelative",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px"
            }),
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            })
        },
        {
            id: "ownerFirstName",
            header: Constants.Common.names,
            accessor: "ownerFirstName",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            })
        },
        {
            id: "ownerLastName",
            header: Constants.Common.lastNames,
            accessor: "ownerLastName",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            })
        },
        {
            id: "type",
            header: Constants.Common.type,
            accessor: "",
            render: (data: AppliedCreditDTO) => <span
                style={{
                    color: data.statusId === appliedCreditStatus.CANCELLED
                        ? Constants.Styles.text.colors.red
                        : Constants.Styles.text.colors.blackPrimary,
                    font: Constants.Styles.text.fonts.robotoBold16,
                    marginBottom: 0
                }}>
                {data.type}
            </span>,
            filterType: FILTER_TYPES.MULTISELECT,
            filterOptions: APPLIED_CREDIT_TYPES,
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px"
            }),
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            })
        },
        {
            id: "invoiceId",
            header: Constants.Common.appliedCreditsInvoice,
            accessor: "",
            filterType: FILTER_TYPES.INPUT_TEXT,
            render: (credit: any) => credit.invoiceId > 0
                ? <ButtonComponent className="wa-btn sm link"
                    tooltip={Constants.Common.invoiceDetail}
                    style={{ opacity: idleLocked ? 0.5 : 1 }}
                    disabled={idleLocked}
                    onClick={() => getInvoiceById(credit.invoiceId)}
                >
                    {credit.invoiceId.toString()}
                </ButtonComponent>
                : "-",
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px"
            })
        },
        {
            id: "amount",
            header: Constants.Common.amount,
            accessor: "",
            render: (credit) => `${credit.amount.moneyFormat}`,
            filterType: FILTER_TYPES.AMOUNT_RANGE,
            quantityRangeState: {
                option: amount.option,
                setOption: (option: string) => setAmount((prev) => ({ ...prev, option })),
                quantity: amount.quantity,
                setQuantity: (quantity: number) => setAmount((prev) => ({ ...prev, quantity })),
            },
            filterOptions: OPERATORS,
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                textAlign: "right",
                whiteSpace: "inherit",
                width: "200px"
            }),
        },
        {
            id: "createdAt",
            header: Constants.Common.createdAt,
            accessor: "createdAt",
            filterType: FILTER_TYPES.DATE_RANGE,
            dateRangeState: {
                start: createdAtRange.start,
                end: createdAtRange.end,
                setStart: (date: Date | null) => setCreatedAtRange((prev) => ({ ...prev, start: date })),
                setEnd: (date: Date | null) => setCreatedAtRange((prev) => ({ ...prev, end: date })),
            },
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            }),
        },
        {
            id: "status",
            header: Constants.Common.status,
            accessor: "",
            render: (data: AppliedCreditDTO) => <span
                style={{
                    color: data.statusId === appliedCreditStatus.CANCELLED
                        ? Constants.Styles.text.colors.red
                        : Constants.Styles.text.colors.blackPrimary,
                    font: Constants.Styles.text.fonts.robotoBold16,
                    marginBottom: 0
                }}>
                {data.status}
            </span>,
            filterType: FILTER_TYPES.MULTISELECT,
            filterOptions: appliedCreditStatuses,
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            })
        },
        {
            id: "notes",
            header: Constants.Common.notes,
            accessor: "",
            filterType: FILTER_TYPES.INPUT_TEXT,
            render: (credit: AppliedCreditDTO) => (
                <TruncateTextComponent
                    value={credit.notes}
                    maxLength={10}
                    maxWidth={"100%"}
                />
            ),
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "100px"
            }),
        },
        {
            id: "selectInvoice",
            header: "",
            accessor: "",
            render: () => <React.Fragment></React.Fragment>,
            overrideCellStyle: () => ({
                textAlign: "right",
                borderBottom: 0
            }),
            overrideHeaderStyle: () => ({
                textAlign: "left",
                color: "black"
            })
        }
    ];

    const creditsColumnWithLogic = creditsColumn.map((column: any) => {
        if (column.id === "selectInvoice" && column.render) {
            return {
                ...column,
                render: (credit: any) => (
                    <OverlayTrigger overlay={
                        <Tooltip>
                            {Constants.Common.creditDetail}
                        </Tooltip>
                    }>
                        <button
                            className="btn btn-default"
                            type="button"
                            disabled={idleLocked}
                            onClick={() => showCreditDetail(credit)}
                        >
                            <FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
                        </button>
                    </OverlayTrigger>
                ),
            };
        }
        return column;
    });

    const handlePageSizeChange = (newPageSize: number) => {
        setItemsPerPage(newPageSize);
    }

    const resetStateData = () => {
        dispatch(setRefundItems(new Set()));
        dispatch(setIsPartialPayment(false));
    }

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{
                color: "#FF0000",
                display: "inline-block"
            }}>
                Error
            </h3>
        }));
    }

    const getStudentById = async (studentId: number) => {
        try {
            let response = await http.studentService.getStudentById(
                currentCampus.id,
                studentId
            );
            let student = response.data;
            return student;
        } catch (error) {
            console.log(error);
            onError(getErrorMessage(error));
        }
    }

    const setSelectedStudent = async (data: any) => {
        const studentData = await getStudentById(data.studentId);
        dispatch(setStudentData(studentData));
    }

    const fetchRoleData = async () => {
        try {
            const response = await http.roleService.getRoleData(currentRole.id);
            dispatch(setPermissions(response.data.permissions));
            return response.data;
        } catch (err: any) {
            console.error(err);
        }
    }

    const getAppliedCreditsByCampusId = async () => {
        try {
            const response = await http.appliedCreditService.getAppliedCreditsByCampusId(
                currentCampus.id,
                itemsPerPage,
                filters
            );
            return response;
        } catch (err: any) {
            console.error(err);
        }
    }

    const handleDownloadReport = async () => {
        try {
            dispatch(enableIdleLockSwitch());
            await dispatch(handleCreditsXlsx({
                campusId: currentCampus.id,
                criteria: filters
            })).unwrap(); // unwrap para manejar correctamente errores en createAsyncThunk;
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const getPaginatedCredits = async (url: string) => {
        dispatch(enableIdleLockSwitch())
        try {
            const response = await http.appliedCreditService.getPaginatedCredits(url);
            const appliedCredits = response.data;
            updatePaginationInfo(appliedCredits);
            setAppliedCredits(appliedCredits.data);
            return appliedCredits;
        } catch (err: any) {
            console.error(err);
        } finally {
            setIsLoaded(true);
            dispatch(disableIdleLockSwitch());
        }
    }

    const updatePaginationInfo = (pagination: any) => {
        const updatedPages = pagination.links.map((page: any) => ({
            ...page,
            onClick: () => {
                if (page.url) {
                    getPaginatedCredits(page.url);
                }
            }
        }));
        setItemCount({
            start: pagination.from,
            end: pagination.to,
            total: pagination.total
        });
        setPages(updatedPages);
        setCurrentPage(pagination.current_page);
    }

    const showPaymentDetail = async (rowData: any) => {
        try {
            dispatch(enableIdleLockSwitch());
            const response = await dispatch(handleSelectedPayment({
                sessionToken: userData?.token ?? "",
                campusId: currentCampus.id,
                paymentId: rowData.id
            })).unwrap();
            const payment = {
                ...response.data,
                account: response.data.recipientAccount,
                amount: response.data.amount.value,
                files: response.data.documents
            };
            dispatch(setSelectedPayment(payment));
            dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL));
            dispatch(setShowModal(true));
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const showCreditDetail = async (rowData: any) => {
        try {
            dispatch(enableIdleLockSwitch());
            let response = await dispatch(handleSelectedCredit({
                campusId: currentCampus.id,
                creditId: rowData.id
            })).unwrap();
            const credit = response.data;
            dispatch(setSelectedCredit(credit));
            dispatch(setModalContent(FINANCES_MODALS.APPLY_CREDIT_DETAILS));
            dispatch(setShowModal(true));
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const cancelInvoice = () => {
        dispatch(setModalContent(FINANCES_MODALS.CANCEL_INVOICE));
    }

    const proccessPayment = () => {
        dispatch(setIsPartialPayment(true));
        dispatch(setIsNewInvoice(false));
        dispatch(setModalContent(FINANCES_MODALS.EMIT_PAYMENT));
    }

    const closeModalInvoiceDetail = () => {
        dispatch(setIsPartialPayment(false));
        dispatch(setShowModal(false));
        dispatch(setSelectedInvoice(null));
    }

    const handleCancelPayment = () => {
        setIsCancelPayment(true);
        dispatch(setModalContent(FINANCES_MODALS.CANCEL_PAYMENT));
    }

    const paymentDetailCloseModal = () => {
        dispatch(setShowModal(false));
    }

    const showPaymentUpdateModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.UPDATE_PAYMENT));
    }

    const updatePaymentCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.INVOICE))
    }

    const onSuccessUpdatePayment = () => {
        dispatch(setModalContent(FINANCES_MODALS.UPDATE_PAYMENT));
        showSuccessMessage();
        showPaymentDetail(selectedPayment);
    }

    const onSuccessApplyCredit = () => {
        fetchData();
        dispatch(setShowModal(false));
        showSuccessMessage();
    }

    const cancelApplyCredit = () => {
        if (isPartialPayment && !isNewInvoice) {
            dispatch(setModalContent(FINANCES_MODALS.INVOICE));
        } else {
            dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
        }
    }

    const closeModalCancelApplyCredit = () => {
        dispatch(setShowModal(false));
    }

    const creditDetailCloseModal = () => {
        dispatch(setShowModal(false));
    }

    const showSuccessMessage = () => {
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }));
    }

    const cancelPaymentCloseModal = () => {
        setIsCancelPayment(false);
        dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL));
    }

    const onSuccessCancelPayment = () => {
        fetchData();
        if (selectedInvoice) {
            getInvoiceById(selectedInvoice.id);
        }
        dispatch(setShowModal(true));
        showPaymentDetail(selectedPayment);
        showSuccessMessage();
    }

    const cancelEmitPayment = () => {
        if (isPartialPayment && !isNewInvoice) {
            dispatch(setModalContent(FINANCES_MODALS.INVOICE));
        } else {
            dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
        }
    }

    const showApplyCreditModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.APPLY_CREDIT));
    }

    const onSuccessEmitPayment = () => {
        fetchData();
        getInvoiceById(selectedInvoice.id);
        dispatch(setShowModal(true));
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }));
    }

    const closeModalEmitPayment = () => {
        dispatch(setShowModal(false));
    }

    const cancelInvoiceCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.INVOICE));
    }

    const onSuccessCancelInvoice = () => {
        fetchData();
        dispatch(setShowModal(false));
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }))
    }

    const generateInvoiceCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
    }

    const onSuccessGeneratePaidInvoice = () => {
        fetchData();
        dispatch(setShowModal(false));
        showSuccessMessage();
    }

    const fetchData = async () => {
        resetStateData();
        dispatch(enableIdleLockSwitch())
        if (currentCampus && userData) {
            try {
                const [appliedCredits] = await Promise.all([
                    getAppliedCreditsByCampusId()
                ]);
                setAppliedCredits(appliedCredits?.data.data);
                updatePaginationInfo(appliedCredits?.data);
                dispatch(disableIdleLockSwitch());
                setIsLoaded(true);
            }
            catch (error: any) {
                dispatch(disableIdleLockSwitch());
                if (error.status === 404) {
                    navigate("/");
                }
                else {
                    console.log(error);
                    onError(getErrorMessage(error));
                }
            }
        }
    }

    const getInvoiceById = async (invoiceId: number) => {
        try {
            dispatch(enableIdleLockSwitch());
            let response = await dispatch(handleSelectedInvoice({
                sessionToken: userData?.token ?? "",
                campusId: currentCampus.id,
                studentId: studentData.id,
                invoiceId
            })).unwrap();
            let invoice = response.data;
            setSelectedStudent(invoice);
            dispatch(setSelectedInvoice(invoice));
            dispatch(setIsNewInvoice(false));
            dispatch(setShowModal(true));
            dispatch(disableIdleLockSwitch());
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    useEffect(() => {
        if (selectedInvoice && Object.keys(selectedInvoice).length > 0) {
            dispatch(setShowModal(true));
            dispatch(setModalContent(FINANCES_MODALS.INVOICE));
        }
    }, [selectedInvoice]);

    useEffect(() => {
        fetchRoleData();
    }, [currentRole]);

    useEffect(() => {
        if (userData?.token && currentCampus !== null) {
            if (filters?.search === "") {
                setCurrentPage(1);
                fetchData();
                return;
            }
            fetchData();
        } else {
            onError(
                "No hay planteles registrados o asignados al rol en uso por el usuario, " +
                "por lo que el acceso a este módulo permanecerá deshabilitado."
            )
        }
    }, [userData, currentCampus, itemsPerPage, filters]);

    return !isLoaded || currentCampus === null || userData === null
        ? <React.Fragment></React.Fragment>
        : <CredistView
            appliedCreditsProps={{
                appliedCredits,
                columns: creditsColumnWithLogic,
                filters,
                onFilterChange,
                onClearFilters,
                reportFunction: handleDownloadReport
            }}
            paginationProps={{
                pages,
                pagesSize,
                itemCount,
                handlePageSizeChange
            }}
            searchProps={{
                search: searchValue,
                onChangeSearch,
                onClickSearch,
                onClearSearch
            }}
            invoiceDetailProps={{
                cancelInvoice,
                showPaymentDetail,
                showCreditDetail,
                proccessPayment,
                closeModal: closeModalInvoiceDetail
            }}
            emitPaymentProps={{
                isNewInvoice,
                isPartialPayment,
                closeModal: closeModalEmitPayment,
                cancelEmitPayment,
                showApplyCreditModal,
                onSuccess: onSuccessEmitPayment
            }}
            paymentDetailProps={{
                handleCancelPayment,
                closeModal: paymentDetailCloseModal,
                showUpdatePayment: showPaymentUpdateModal
            }}
            paymentUpdateProps={{
                onSuccess: onSuccessUpdatePayment,
                closeModal: updatePaymentCloseModal
            }}
            cancelPaymentProps={{
                closeModal: cancelPaymentCloseModal,
                onSuccess: onSuccessCancelPayment
            }}
            cancelInvoiceProps={{
                closeModal: cancelInvoiceCloseModal,
                onSuccess: onSuccessCancelInvoice
            }}
            generateInvoiceProps={{
                onSuccess: onSuccessGeneratePaidInvoice,
                closeModal: generateInvoiceCloseModal
            }}
            applyCreditProps={{
                isNewInvoice,
                isPartialPayment,
                closeModal: closeModalCancelApplyCredit,
                cancelApplyCredit,
                onSuccess: onSuccessApplyCredit
            }}
            appliedCreditsDetailProps={{
                closeModal: creditDetailCloseModal,
            }}
        />
}

export default CreditsController;