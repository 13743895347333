import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React,
{
    useEffect,
    useState
} from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import {
    AppDispatch,
    disableIdleLockSwitch,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../globals";
import Constants,
{
    FILTER_TYPES,
    studentStatus,
    TRANSFER_MODAL_CONTENT
} from "../../../constants";
import { styles } from "./style";
import CommonTable, { Column } from "../../commonTable";
import SearchInputComponent from "../../searchInput/searchInput";
import { StudentDTO } from "../../../models/StudentDTO";
import HttpManager from "../../../services/HttpManager";
import PaginationComponent from "../../pagination/pagination";
import { getErrorMessage } from "../../../common";
import { setTransferModalContent, setShowTransferCreditModal, setStudentWhoReceives } from "../../../redux/reducers/accountStatementReducer";

export interface SelectStudentProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
    * Student who transfer credit.
    */
    fromStudent: StudentDTO;
}

const SelectStudentComponent: React.FC<SelectStudentProps> = ({
    fromStudent
}) => {

    const http = HttpManager.getInstance();
    const dispatch = useDispatch<AppDispatch>();
    const pagesSize: number[] = [10];
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const [students, setStudents] = useState<any>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [pages, setPages] = useState<any>([
        {
            "url": null,
            "label": "&laquo; Previous",
            "active": false
        },
        {
            "url": "",
            "label": "1",
            "active": true
        },
        {
            "url": null,
            "label": "Next &raquo;",
            "active": false
        }
    ]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(pagesSize[0]);
    const [itemCount, setItemCount] = useState<any>({
        start: 0,
        end: 0,
        total: 0
    });
    const [searchValue, setSearchValue] = useState<string>("");
    const [filters, setFilters] = useState<{ [key: string]: any }>({});
    const studentColumns: Column[] = [
        {
            id: "id",
            header: Constants.Common.id,
            accessor: "id",
            filterType: FILTER_TYPES.INPUT_NUMBER,
            overrideCellStyle: (student: StudentDTO) => ({
                color: fromStudent.id === student.id
                    || student.statusId !== studentStatus.ACTIVE
                    ? Constants.Styles.text.colors.grayOpacity
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "150px"
            })
        },
        {
            id: "correlative",
            header: Constants.Common.correlative,
            accessor: "correlative",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideCellStyle: (student: StudentDTO) => ({
                color: fromStudent.id === student.id
                    || student.statusId !== studentStatus.ACTIVE
                    ? Constants.Styles.text.colors.grayOpacity
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px"
            }),
        },
        {
            id: "firstName",
            header: Constants.Common.names,
            accessor: "firstName",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideCellStyle: (student: StudentDTO) => ({
                color: fromStudent.id === student.id
                    || student.statusId !== studentStatus.ACTIVE
                    ? Constants.Styles.text.colors.grayOpacity
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px"
            }),
        },
        {
            id: "lastName",
            header: Constants.Common.lastNames,
            accessor: "lastName",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideCellStyle: (student: StudentDTO) => ({
                color: fromStudent.id === student.id
                    || student.statusId !== studentStatus.ACTIVE
                    ? Constants.Styles.text.colors.grayOpacity
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px"
            }),
        },
        {
            id: "selectStudent",
            header: "",
            accessor: "",
            render: () => <React.Fragment></React.Fragment>,
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                borderBottom: 0,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px"
            }),
        }
    ];

    const studentsColumnWithLogic = studentColumns.map((column: any) => {
        if (column.id === "selectStudent" && column.render) {
            return {
                ...column,
                render: (student: StudentDTO) => (
                    <button type="button"
                        onClick={() => showTransferModal(student)}
                        disabled={
                            idleLocked
                            || fromStudent?.generalBalance.positiveBalance.value <= 0
                            || student.statusId !== studentStatus.ACTIVE
                            || fromStudent.id === student.id
                        }
                        className="btn btn-outline-success"
                        style={{
                            borderRadius: "25px",
                            display: "flex"
                        }} >
                        <FontAwesomeIcon
                            icon={solid("money-bill-transfer")}
                            style={{
                                marginTop: "4px"
                            }} />
                        <span
                            className="d-xs-none d-sm-none d-lg-none d-xl-block"
                            style={{
                                paddingLeft: "10px"
                            }}>
                            {Constants.Common.transferCredit}
                        </span>
                    </button>
                ),
            };
        }
        return column;
    });

    const showTransferModal = (student: StudentDTO) => {
        dispatch(setStudentWhoReceives(student))
        dispatch(setTransferModalContent(TRANSFER_MODAL_CONTENT.TRANSFER))
        dispatch(setShowTransferCreditModal(true));
    }

    const onChangeSearch = (newSearchValue: string) => {
        setSearchValue(newSearchValue);
    }

    const onClickSearch = () => {
        setFilters({ ...filters, search: searchValue });
    }

    const onClearSearch = () => {
        setSearchValue("");
        setFilters({});
    }

    const onClearFilters = () => {
        setSearchValue("");
        setFilters({});
    }

    const updateFilters = (prevFilters: any, columnId: string, value: any) => {
        const updatedFilters = { ...prevFilters };

        if (value === null) {
            if (updatedFilters[columnId]) {
                delete updatedFilters[columnId];
            }
        } else {
            updatedFilters[columnId] = value;
        }

        if (Array.isArray(value)) {
            if (value.length === 0) {
                delete updatedFilters[columnId];
            } else {
                updatedFilters[columnId] = value;
            }
        }

        return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
    };

    const onFilterChange = (columnId: string, value: any) => {
        setFilters((prevFilters) => {
            const newFilters = updateFilters(prevFilters, columnId, value);
            return newFilters;
        });
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setItemsPerPage(newPageSize);
    }

    const getStudentsByCampusId = async () => {
        try {
            const response = await http.studentService.getStudentsByCampusId(
                currentCampus.id,
                itemsPerPage,
                filters
            );
            return response;
        } catch (err: any) {
            console.error(err);
        }
    }

    const getPaginatedStudents = async (url: string) => {
        dispatch(enableIdleLockSwitch())
        try {
            const response = await http.studentService.getPaginatedStudents(url);
            const students = response.data;
            updatePaginationInfo(students);
            setStudents(students.data);
            return students;
        } catch (err: any) {
            console.error(err);
        } finally {
            setIsLoaded(true);
            dispatch(disableIdleLockSwitch());
        }
    }

    const updatePaginationInfo = (pagination: any) => {
        const updatedPages = pagination.links.map((page: any) => ({
            ...page,
            onClick: () => {
                if (page.url) {
                    getPaginatedStudents(page.url);
                }
            }
        }));
        setItemCount({
            start: pagination.from,
            end: pagination.to,
            total: pagination.total
        });
        setPages(updatedPages);
        setCurrentPage(pagination.current_page);
    }

    const fetchData = async () => {
        // resetStateData();
        dispatch(enableIdleLockSwitch())
        try {
            const students = await getStudentsByCampusId();
            setStudents(students?.data.data);
            updatePaginationInfo(students?.data);
            dispatch(disableIdleLockSwitch());
            setIsLoaded(true);
        }
        catch (error: any) {
            dispatch(disableIdleLockSwitch());
            console.log(error);
            onError(getErrorMessage(error));
        }
    }

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                Error
            </h3>
        }))
    }

    useEffect(() => {
        fetchData();
    }, [filters]);

    return (
        <div style={styles.SelectStudentContainer}>
            <SearchInputComponent
                search={searchValue}
                onChangeSearch={onChangeSearch}
                onClickSearch={onClickSearch}
                onClearSearch={onClearSearch}
            />
            <CommonTable                
                columns={studentsColumnWithLogic}
                data={students}
                filters={filters}
                onFilterChange={onFilterChange}
                onClearFilters={onClearFilters}
            />
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                position: "sticky",
                bottom: -20,
                marginBottom: 0,
                paddingRight: 100,
                background: "#FFFFFF"
            }}>
                <PaginationComponent
                    pages={pages}
                    onPageSizeChange={handlePageSizeChange}
                    pageSizes={pagesSize}
                    itemCount={itemCount}
                />
            </div>
        </div>
    )
}

export default SelectStudentComponent;