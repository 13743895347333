import { useForm } from "react-hook-form";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MiniModalController from '../../../../../components/modals/miniModal/miniModalController'
import { ExtraStudentFormData, ExtraStudentModalViewProps } from './interfaces';
import SearchInputComponent from '../../../../../components/searchInput/searchInput';
import CommonTable from '../../../../../components/commonTable';
import PaginationComponent from '../../../../../components/pagination/pagination';
import Constants from "../../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

//styles
import { styles } from "./styles";
import "./styles.css";

const ExtraStudentModalView = ({
    showExtraStudentModal,
    closeExtraStudentModal,
    pages,
    handlePageSizeChange,
    pagesSize,
    itemCount,
    searchValue,
    onChangeSearch,
    onClickSearch,
    onClearSearch,
    studentsColumnWithLogic,
    students,
    filters,
    onFilterChange,
    onClearFilters,
    idleLocked,
    onSubmit,
    customCloseModal
}: ExtraStudentModalViewProps) => {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<ExtraStudentFormData>();

    return (
        <MiniModalController
            title="Añadir Estudiante"
            show={showExtraStudentModal}
            onClose={customCloseModal}
        >
            <Tabs
                defaultActiveKey="search"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="search" title="Buscar" color="green">
                    <div style={styles.container.v}>
                        <SearchInputComponent
                            search={searchValue}
                            onChangeSearch={onChangeSearch}
                            onClickSearch={onClickSearch}
                            onClearSearch={onClearSearch}
                        />
                        {
                            !idleLocked ?
                                <CommonTable
                                    columns={studentsColumnWithLogic}
                                    data={students}
                                    filters={filters}
                                    onFilterChange={onFilterChange}
                                    onClearFilters={onClearFilters}
                                />
                                :
                                <span>Cargando...</span>
                        }
                    </div>
                    <div
                        className="styles_container_footer_v"
                        style={styles.container.footer.v}>
                        <PaginationComponent
                            pages={pages}
                            onPageSizeChange={handlePageSizeChange}
                            pageSizes={pagesSize}
                            itemCount={itemCount}
                            style={{ padding: "0 10px" }}
                        />
                    </div>
                </Tab>
                <Tab eventKey="new" title="Nuevo">
                    <form className="d-flex flex-column gap-2" onSubmit={handleSubmit((data) => {
                        reset();
                        onSubmit(data);
                    })}>
                        <input
                            id="firstName"
                            type="text"
                            {...register("firstName",
                                {
                                    required: 'El campo Nombre es requerido.'
                                }
                            )}
                            placeholder={"Nombre"}
                            style={styles.container.form.input.v}
                        />
                        {errors.firstName &&
                            <p style={
                                {
                                    color: Constants.Styles.text.colors.red,
                                    width: "100%",
                                    font: Constants.Styles.text.fonts.robotoNormal16,
                                    padding: "5px",
                                    marginBottom: 0
                                }
                            }>
                                {errors.firstName.message}
                            </p>
                        }
                        <input
                            id="lastName"
                            type="text"
                            {...register("lastName",
                                {
                                    required: 'El campo Apellidos es requerido.'
                                }
                            )}
                            placeholder={"Apellidos"}
                            style={styles.container.form.input.v}
                        />
                        {errors.lastName &&
                            <p style={
                                {
                                    color: Constants.Styles.text.colors.red,
                                    width: "100%",
                                    font: Constants.Styles.text.fonts.robotoNormal16,
                                    padding: "5px",
                                    marginBottom: 0
                                }
                            }>
                                {errors.lastName.message}
                            </p>
                        }
                        <input
                            id="email"
                            type="email"
                            {...register("email")}
                            placeholder={"Correo"}
                            style={styles.container.form.input.v}
                        />
                        <input
                            id="phone"
                            type="text"
                            {...register("phone")}
                            placeholder={"Telefono"}
                            style={styles.container.form.input.v}
                        />
                        <textarea id="observations"
                            {
                            ...register('observations',
                                {
                                    required: 'El campo notas es requerido.'
                                }
                            )}
                            name="observations"
                            placeholder={Constants.Common.notes}
                            style={styles.container.form.textarea.v}
                        />
                        {errors.observations &&
                            <p style={
                                {
                                    color: Constants.Styles.text.colors.red,
                                    width: "100%",
                                    font: Constants.Styles.text.fonts.robotoNormal16,
                                    padding: "5px",
                                    marginBottom: 0
                                }
                            }>
                                {errors.observations.message}
                            </p>
                        }
                        <button
                            disabled={idleLocked}
                            className="btn btn-outline-success"
                            style={{
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px"
                            }}
                            type="submit">
                            <FontAwesomeIcon icon={solid("plus")} />
                            <span
                                className="d-xs-none d-sm-none d-lg-none d-xl-block"
                                style={{
                                    paddingLeft: "5px"
                                }}>
                                {"Añadir"}
                            </span>
                        </button>
                    </form>
                </Tab>
            </Tabs>
        </MiniModalController>
    )
}

export default ExtraStudentModalView