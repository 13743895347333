//imports
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash';
import { ENROLLMENT_STATUS, TIME_STATUS, COLOR_STATUS } from '../../../../../constants';
import { disableIdleLockSwitch, displayNotice, enableIdleLockSwitch, RootState } from '../../../../../globals'
import { AllowedFileType, AttendancesModalControllerProps, Chat, FormatedSession, ExtraStudent, ExtraStudentList, ExtraStudentPayload, HasChanges } from './interfaces';
import { SessionDTO, Document } from '../../../../../models/SessionDTO';
import { AttendanceStudentDTO } from '../../../../../models/AttendanceStudentDTO';

//services
import HttpManager from '../../../../../services/HttpManager';

//view
import AttendancesModalView from './attendancesModalView'
import { AttendanceHistoryDTO } from '../../../../../models/AttendanceHistoryDTO';

//consts
const allowedFiletypes: AllowedFileType[] = [
    { file: "image/png", type: "png" },
    { file: "image/jpeg", type: "jpeg" },
    { file: "image/webp", type: "webp" },
    { file: "image/heic", type: "heic" }
];

const AttendancesModalController = ({
    selectedSchedule,
    setSelectedSchedule,
    miniMessage
}: AttendancesModalControllerProps) => {

    //init http
    const http = HttpManager.getInstance();

    //init dispatch
    const dispatch = useDispatch();

    //useSelector
    const currentUserCampus : any = useSelector((state : RootState) => state.currentUserCampus.value);
    const campusRef : any = useSelector((state : RootState) => state.campusRef.value);
    const idleLocked : boolean = useSelector((state : RootState) => state.idleLockSwitch.value);
    const userProfile : any = useSelector((state : RootState) => state.userProfile.value);

    //useState
    const [selectedSession, setSelectedSession] = useState<FormatedSession | null>(null);
    const [selectedAttendance, setSelectedAttendance] = useState<AttendanceStudentDTO[] | null>(null);
    const [editableAttendance, setEditableAttendance] = useState<any>(null);
    const [baseEditableAttendance, setBaseEditableAttendance] = useState<any>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [sessionsCount, setSessionsCount] = useState<number>(0);
    const [sessionOptions, setSessionOptions] = useState<FormatedSession[] | undefined>();
    const [uploadDocuments, setUploadDocuments] = useState<File[]>([]);
    const [downloadDocuments, setDownloadDocuments] = useState<Document[]>([]);
    const [isNewAttendance, setIsNewAttendance] = useState<boolean>(false);
    const [isShowNoteModal, setIsShowNoteModal] = useState<boolean>(false);
    const [selectedNote, setSelectedNote] = useState<any>([]);
    const [selectedNoteId, setSelectedNoteId] = useState<number | null>(null);
    const [extraStudentsList, setExtraStudentsList] = useState<ExtraStudentList>({});
    const [baseExtraStudentsList, setBaseExtraStudentsList] = useState<ExtraStudentList>({});
    const [showExtraStudentModal, setShowExtraStudentModal] = useState<boolean>(false);
    const [historicAttendances, setHistoricAttendances] = useState<AttendanceHistoryDTO[]>([]);
    const [sessions, setSessions] = useState<FormatedSession[]>([]);
    const [isLecture, setIsLecture] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>('attendances');
    const [showExtraStudentModalLecture, setShowExtraStudentModalLecture] = useState<boolean>(false);
    const [extraStudentsListLecture, setExtraStudentsListLecture] = useState<ExtraStudentList>({});
    const [initialStudentsCount, setInitialStudentsCount] = useState<number>(0);
    const [currentStudentsCount, setCurrentStudentsCount] = useState<number>(0);

    //useEffect
    useEffect(() => {
        if (selectedSchedule) {
            selectSchedule(selectedSchedule.scheduleId)
        }
    }, [selectedSchedule]);

    const selectSchedule = async (scheduleId: number) => {
        try {
            setShowModal(true);
            dispatch(enableIdleLockSwitch());

            const result = await http.scheduleService.getScheduleSessions(campusRef[currentUserCampus].id, scheduleId);
            getAttendancesHistory(scheduleId);
            
            if ((result.status == 200 || result.status == 204) && result.data) {
                const sessions = result.data;
                let count: number = 0;
                let todaySession: FormatedSession | null = null;
                let lastSession: FormatedSession | null = null;
                let newSessions: FormatedSession[] = [];
                let formattedSessions: FormatedSession[] = [];
                
                sessions.map((session: SessionDTO, index) => {
                    let formattedSession: FormatedSession = {
                        ...session,
                        label: `${session.sessionOn.split("/")[0]} de ${session.sessionOnMonth}`,
                        value: session.sessionId,
                        color: session.hasAttendances? COLOR_STATUS.FINALIZED : (session.isOnCurrentDay? COLOR_STATUS.AVAILABLE : COLOR_STATUS.INCOMPLETE)
                    }
                    if (index == 0) {
                        setInitialStudentsCount(session.presentStudents);
                    }
                    if (session.timeStatusId != TIME_STATUS.NOT_STARTED || (userProfile.currentRole == "mentor" && session.isOnCurrentDay)) {
                        count++;
                        if (session.isOnCurrentDay) {
                            todaySession = formattedSession;
                        }
                        lastSession = formattedSession;
                        newSessions.push(formattedSession);
                    }
                    formattedSessions.push(formattedSession);
                });

                const lastSessionWithAttendances = sessions.filter((session: SessionDTO) => session.hasAttendances).sort((a, b) => b.sessionId - a.sessionId)[0]; 
                setCurrentStudentsCount(lastSessionWithAttendances? lastSessionWithAttendances.presentStudents : 0);
                
                setSessions(formattedSessions);
                setSessionOptions(newSessions);
                setSessionsCount(count);
                const currentSession = todaySession? todaySession : lastSession;

                if (currentSession) {
                    setSelectedSession(currentSession);
                    await selectSession(currentSession);
                }
            } else {
                miniMessage(
                    "Error",
                    "No se pudo obtener la información de las sesiones"
                )
            }
        }
        catch (error) {
            console.log(error)
            miniMessage(
                "Error",
                "No se pudo obtener la información de las sesiones"
            )
        } finally {
            dispatch(disableIdleLockSwitch())
        }
    }

    const getAttendancesHistory = async(scheduleId: number) => {
        try {
            dispatch(enableIdleLockSwitch());
            const result = await http.attendanceService.getAttendancesHistory(campusRef[currentUserCampus].id, scheduleId);
            if ((result.status == 200 || result.status == 204) && result.data) {
                setHistoricAttendances(result.data)
            }
        } catch (error) {
            console.log(error)
            miniMessage(
                "Error",
                "No se pudo obtener la información del historico"
            )
        } finally {
            dispatch(disableIdleLockSwitch())
        }
    }

    const selectSession = async (session: SessionDTO) => {
        session.hasAttendances ? setIsNewAttendance(false) : setIsNewAttendance(true);
        session.documents.length > 0? setDownloadDocuments(session.documents) : setDownloadDocuments([])

        try {
            dispatch(enableIdleLockSwitch());
            const result = await http.attendanceService.getSessionAttendances(campusRef[currentUserCampus].id, selectedSchedule.scheduleId, session.sessionId);
            const formatedStudents = await getExtraStudents(session);
            setExtraStudentsList(formatedStudents)
            setBaseExtraStudentsList(structuredClone(formatedStudents))
            
            if ((result.status == 200 || result.status == 204) && result.data) {
                const enrollments = result.data.sort(function (a, b){
                    return a.enrollmentStatusSortOrder - b.enrollmentStatusSortOrder
                });
                let formatedEnrollments: any = {};
                enrollments.map((enrollment: AttendanceStudentDTO) => formatedEnrollments = {
                    ...formatedEnrollments,
                    [enrollment.enrollmentId]: enrollment
                });
                setSelectedAttendance(enrollments);
                setBaseEditableAttendance(formatedEnrollments);
                setEditableAttendance(formatedEnrollments);
            } else {
                miniMessage(
                    "Error",
                    "No se pudo obtener los datos de la asistencia"
                )
            }
        }
        catch (error) {
            console.log(error)
            miniMessage(
                "Error",
                "No se pudo obtener los datos de la asistencia"
            )
        } finally {
            dispatch(disableIdleLockSwitch())
        }
    }

    const getExtraStudents = async(session: SessionDTO) => {
        try {
            dispatch(enableIdleLockSwitch());
            const result = await http.attendanceService.getExtraStudentsByClass(campusRef[currentUserCampus].id, selectedSchedule.scheduleId, session.sessionId);
            if ((result.status == 200 || result.status == 204) && result.data) {
                const extraStudents = result.data;
                let formatedStudents: any = {};
                extraStudents.map((extraStudent, index:number) => {
                    let id = extraStudent.studentId == 0? index : extraStudent.studentId;
                    formatedStudents = {
                        ...formatedStudents,
                        [id]: {
                            ...extraStudent,
                            temporalId: id
                        }
                    }
                });
                return formatedStudents;
            } else {
                miniMessage(
                    "Error",
                    "No se pudo obtener los datos de los alumnos extra"
                )
            }
        } catch (error) {
            console.log(error)
            miniMessage(
                "Error",
                "No se pudo obtener los datos de los alumnos extra"
            )
        } finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const saveSession = async (callbackFn?: any) => {

        let hasChangesResult = hasChanges(isNewAttendance);

        if (!hasChangesResult.status) {
            miniMessage(
                "Faltan Datos",
                `Los datos no se guardaron ya que falta
                ${!hasChangesResult.hasData? ' modificar al menos un dato.' : ''}
                ${!hasChangesResult.hasDocuments? ' subir al menos un documento.' : ''}`
            )
        } else if (selectedSession) {
            let attendanceValuesArray = Object.values(editableAttendance);
            attendanceValuesArray = attendanceValuesArray.filter((attendance:any) => attendance.enrollmentStatusId == ENROLLMENT_STATUS.ACTIVE);

            let extraStudentsListing: ExtraStudentPayload[] = [];
            const extraStudentsListValues = Object.values(extraStudentsList);
            extraStudentsListValues.map((extraStudent) => {
                if (!extraStudent.classId) {
                    let newExtraStudent: { [key: string]: any } = {};
                    if (extraStudent.studentId) {
                        newExtraStudent = {
                            "studentId": extraStudent.studentId,
                            "observations": extraStudent.observations
                        }
                    }else {
                        Object.entries(extraStudent).map((entrie) => {
                            if (entrie[1] && entrie[0] != "temporalId") {
                                newExtraStudent[entrie[0]] = entrie[1]
                            }
                        })
                    }
                    extraStudentsListing.push(newExtraStudent);
                }
            });
            const payload = {
                attendances: attendanceValuesArray.flatMap((attendance:any) => {
                    return {
                        studentId: attendance.id,
                        isPresent: attendance.isPresent,
                        note: attendance.attendanceNotes
                    }
                }),
                extraStudentsListing: extraStudentsListing,
                attachedFiles: uploadDocuments
            }
            
            try {
                dispatch(enableIdleLockSwitch())

                const service = isNewAttendance? http.attendanceService.registerAttendanceClass : http.attendanceService.updateAttendanceClass;
                const infoMessage = isNewAttendance? "Se registraron las asistencias con exito" : "Se actualizaron las asistencias con exito";
                
                const result = await service(campusRef[currentUserCampus].id, selectedSchedule.scheduleId, selectedSession.sessionId, payload)
                
                if (result.status == 200 || result.status == 204) {
                    hideModal();
                    miniMessage(
                        "Exito",
                        infoMessage
                    )
                } else if (result.status == 400) {
                    miniMessage(
                        "Error",
                        "Hubo un error al procesar los datos."
                    )
                }
                else {
                    miniMessage(
                        "Error",
                        "No se pudo guardar la información"
                    )
                }
            }
            catch (error) {
                console.log(error)
                miniMessage(
                    "Error",
                    "No se pudo guardar la información"
                )
            }
            finally {
                dispatch(disableIdleLockSwitch())
            }
        }
        

    }
    
    const fileChange = (files: File[]) => {
        setUploadDocuments(files)
    }
    
    const changeSession = (session: FormatedSession) => {
        setIsNewAttendance(false);
        setSelectedSession(session);
        setSelectedAttendance(null);
        setBaseEditableAttendance(null);
        setEditableAttendance(null);
        selectSession(session);
        setActiveTab('attendances');
    }

    const toggleStudentAttendance = (enrollmentId: number) => {
        const newIsPresent = !editableAttendance[enrollmentId].isPresent;
        const newAttendanceTotal = newIsPresent? editableAttendance[enrollmentId].attendanceTotal + 1 : editableAttendance[enrollmentId].attendanceTotal - 1;

        setEditableAttendance({
            ...editableAttendance,
            [enrollmentId]: {
                ...editableAttendance[enrollmentId],
                isPresent: newIsPresent,
                attendanceTotal: newAttendanceTotal
            }
        });
    }

    const hideModal = () => {
        setShowModal(false);
        setSelectedSession(null);
        setSelectedAttendance(null);
        setEditableAttendance(null);
        setBaseEditableAttendance(null);
        setSessionsCount(0);
        setSessionOptions(undefined);
        setUploadDocuments([]);
        setSelectedSchedule(null);
        setIsNewAttendance(false);
    }

    const checkPoint = (callbackFn?: any) => {
        if (selectedAttendance && hasChanges().status) {
            dispatch( displayNotice({
                optOutProcedure: callbackFn,
                procedure: async () => { saveSession(callbackFn) },
                message: "¿Desea guardar los cambios realizados en el registro de este día?",
                heading: <h3 style={{ color: "#0000FF", display: "inline-block" }}>
                    Confirme
                </h3>
            }))
        } else {
            callbackFn()
        }
    }

    const downloadFile = async (file: Document) => {
        if (selectedSession) {
            try {
                dispatch(enableIdleLockSwitch());
                let result = await http.attendanceService.getClassDocumentById(
                    campusRef[currentUserCampus].id,
                    selectedSchedule.scheduleId,
                    selectedSession.sessionId,
                    file.id
                );
                if ((result.status == 200 || result.status == 204) && result.data) {
                    const blob = new Blob([result.data], { type: result.headers['content-type'] });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file.name);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url); // Limpia la URL cuando ya no se necesita
                } else {
                    miniMessage(
                        "Error",
                        "Hubo un error al tratar de obtener el documento!"
                    )
                }
            } catch (error) {
                miniMessage(
                    "Error",
                    "Hubo un error al tratar de obtener el documento!"
                )
            }
            finally {
                dispatch(disableIdleLockSwitch());
            }
        }
    }

    const showNoteModal = (enrollment: AttendanceStudentDTO) => {
        setIsShowNoteModal(true);
        let currentNote = enrollment.attendanceNotes
        ?
        [{
            id: enrollment.enrollmentId,
            message: enrollment.attendanceNotes,
            updatedAt: enrollment.attendanceUpdatedAt,
            updatedBy: enrollment.attendanceUpdatedBy
        }]
        :
        [];
        enrollment.attendanceNotes? setSelectedNoteId(null) : setSelectedNoteId(enrollment.enrollmentId);
        setSelectedNote(currentNote);
    }

    const showNoteModalLecture = (enrollment: AttendanceHistoryDTO, note: string) => {
        setIsLecture(true);
        let currentNote = [{
            id: enrollment.enrollmentId,
            message: note,
            updatedAt: '',
            updatedBy: ''
        }]
        setSelectedNote(currentNote);
        setIsShowNoteModal(true);
    }

    const closeNoteModal = () => {
        setIsLecture(false);
        setIsShowNoteModal(false);
        setSelectedNote([]);
    }

    const changeNote = (notes: Chat[]) => {
        notes.map((note: any) => {
            const newNote = note.message;
            note.id = editableAttendance[note.id]? note.id : selectedNoteId;
            setEditableAttendance({
                ...editableAttendance,
                [note.id]: {
                    ...editableAttendance[note.id],
                    attendanceNotes: newNote
                }
            });
        });
    }
    
    const openExtraStudentModal = () => {
        setShowExtraStudentModal(true);
    }
    
    const closeExtraStudentModal = () => {
        setShowExtraStudentModal(false);
    }
    
    const hasChanges = (isNewAttendance?:boolean) => {
        let hasChange: HasChanges = {
            status: false,
            hasData: false,
            hasDocuments: false
        };
        let hasData = !_.isEqual(baseEditableAttendance, editableAttendance) || !_.isEqual(baseExtraStudentsList, extraStudentsList);
        let hasDocuments = uploadDocuments.length > 0;
        if (isNewAttendance && selectedSession ) {
            hasChange = {
                status: hasData && hasDocuments,
                hasData: hasData,
                hasDocuments: hasDocuments
            }
        } else {
            hasChange = {
                status: hasData || hasDocuments,
                hasData: hasData,
                hasDocuments: hasDocuments
            }
        }
        return hasChange;
    }

    const addClasses = (type: string, data?: any) => {
        let classes;
        switch (type) {
            case "enrollment":
                classes = `
                    ${
                        data.generalBalance.expiredMonthlyBalance.value > 0
                        ?
                        " expiredBalance"
                        :
                        ""
                    } ${
                        data.enrollmentStatusId != ENROLLMENT_STATUS.ACTIVE
                        ?
                        " noActive"
                        :
                        (
                            !data.hasKits
                            ?
                            " noKits"
                            :
                            ""
                        )
                    }
                `
                break;
            default:
                classes = "";
                break;
        }
        return classes;
    }

    const selectTab = (tab: string | null) =>{
        if (tab) {
            setActiveTab(tab)
        }
    }

    const openExtraStudentModalLecture = async (session: SessionDTO) =>{
        const formatedStudents = await getExtraStudents(session);
        setExtraStudentsListLecture(formatedStudents);
        setShowExtraStudentModalLecture(true);
    }

    const closeExtraStudentModalLecture = () =>{
        setShowExtraStudentModalLecture(false);
    }

    return (
        <>
            <AttendancesModalView
                selectedSchedule = {selectedSchedule}
                selectedAttendance = {selectedAttendance}
                editableAttendance = {editableAttendance}
                baseEditableAttendance = {baseEditableAttendance}
                selectedSession = {selectedSession}
                sessionOptions = {sessionOptions}
                changeSession = {changeSession}
                idleLocked = {idleLocked}
                checkPoint = {checkPoint}
                toggleStudentAttendance = {toggleStudentAttendance}
                showModal = {showModal}
                hideModal = {hideModal}
                sessionsCount = {sessionsCount}
                userProfile = {userProfile}
                fileChange = {fileChange}
                uploadDocuments = {uploadDocuments}
                downloadDocuments = {downloadDocuments}
                allowedFiletypes = {allowedFiletypes}
                downloadFile = {downloadFile}
                selectedNote = {selectedNote}
                showNoteModal = {showNoteModal}
                isShowNoteModal = {isShowNoteModal}
                closeNoteModal = {closeNoteModal}
                changeNote = {changeNote}
                isNewAttendance = {isNewAttendance}
                baseExtraStudentsList = {baseExtraStudentsList}
                extraStudentsList = {extraStudentsList}
                setExtraStudentsList = {setExtraStudentsList}
                showExtraStudentModal = {showExtraStudentModal}
                openExtraStudentModal = {openExtraStudentModal}
                closeExtraStudentModal = {closeExtraStudentModal}
                hasChanges = {hasChanges}
                addClasses = {addClasses}
                historicAttendances = {historicAttendances}
                sessions = {sessions}
                isLecture = {isLecture}
                showNoteModalLecture = {showNoteModalLecture}
                activeTab = {activeTab}
                selectTab = {selectTab}
                showExtraStudentModalLecture = {showExtraStudentModalLecture}
                closeExtraStudentModalLecture = {closeExtraStudentModalLecture}
                openExtraStudentModalLecture = {openExtraStudentModalLecture}
                extraStudentsListLecture = {extraStudentsListLecture}
                initialStudentsCount = {initialStudentsCount}
                currentStudentsCount = {currentStudentsCount}
            />
        </>
    )
}

export default AttendancesModalController