import { CSSProperties } from "react";

const container: CSSProperties = {
    padding: "20px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "wrap"
}

const pageContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
}

const pageSizeSelect = {
    marginLeft: "10px",
    padding: "10px",
    fontSize: "12px"
}

const sizeOption = {
    padding: "20px"
}

const pageNavigationContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const pageItem = {
    display: "flex",
    justifyContent: "center"
}

export const styles =
{
    container,
    pageContainer,
    pageSizeSelect,
    sizeOption,
    pageNavigationContainer,
    pageItem
}