import { AxiosResponse } from "axios";
import { axiosInstance } from "./axiosInstance";
import { ScheduleDTO } from "../../models/ScheduleDTO";
import { PaginatedResponse } from "../../models/common/PaginatedResponseDTO";
import { SessionDTO } from "../../models/SessionDTO";

const RESOURCE_URL_V2 = "/admin/campus/";

export class ScheduleService {

	getSchedulesByCampusId = async (campusId: number, perPage: number, criteria?: any): Promise<AxiosResponse<PaginatedResponse<ScheduleDTO>>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/_schedules?perPage=${perPage}`;
		if (criteria && typeof criteria === 'object' && Object.keys(criteria).length > 0) {
			try {
				const criteriaJson = JSON.stringify(criteria);
				uri = `${uri}&criteria=${encodeURIComponent(criteriaJson)}`;
			} catch (error) {
				console.error("Error serializando criteria as JSON:", error);
				throw new Error("Invalid criteria format");
			}
		}
		const response = await axiosInstance.get<PaginatedResponse<ScheduleDTO>>(uri);
		return response;
	}
    /**
	 * 
	 * @param campusId 
	 * @param scheduleId 
	 * @returns SessionDTO array
	 */
	getScheduleSessions = async (campusId: number, scheduleId: number): Promise<AxiosResponse<SessionDTO[]>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/schedules/${scheduleId}/_sessions`;
		const response = await axiosInstance.get<SessionDTO[]>(uri);
		return response;
	}

	getPaginatedSchedules = async (uri: string,  criteria?: any): Promise<AxiosResponse<PaginatedResponse<ScheduleDTO>>> => {
		if (criteria && typeof criteria === 'object' && Object.keys(criteria).length > 0) {
			try {
				const criteriaJson = JSON.stringify(criteria);
				uri = `${uri}&criteria=${encodeURIComponent(criteriaJson)}`;
			} catch (error) {
				console.error("Error serializando criteria as JSON:", error);
				throw new Error("Invalid criteria format");
			}
		}
        let response = await axiosInstance.get<PaginatedResponse<ScheduleDTO>>(uri);
        return response;
    }
}