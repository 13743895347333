//imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FullSizeModalViewProps } from "./interfaces";

//styles
import './styles.scss'

const FullSizeModalView = ({
    title,
    onClose,
    idleLocked,
    headerContent,
    bodyContent,
    footerContent
}: FullSizeModalViewProps) => {

    return (
        <div 
            className="attendance-modal-back fullSizeModal flexCenter">
            <div 
                className="attendance-modal fullSizeModal-container">
                <button
                    className="close flexCenter"
                    disabled={idleLocked}
                    onClick={()=>{
                        onClose()
                    }}>
                    <FontAwesomeIcon size="2x" icon={solid("close")} />
                </button>
                <div
                    className="title d-none d-lg-block d-flex flex-row align-items-center mb-2">
                    {title}
                </div>
                <div 
                    className="content card">
                    <div 
                        className="header mb-2 d-flex flex-column">
                        {headerContent}
                    </div>
                    <div 
                        className="modal-body body overflow-auto">
                        {bodyContent}
                    </div>
                    <div className="footer">
                        {footerContent}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FullSizeModalView