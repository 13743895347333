import { CSSProperties } from "react";

export const styles = {
    modal: {
        header: {
            title: {
                v: <CSSProperties> { 
                    fontSize: "24px",
                    fontWeight: "unset",
                    color: "#8F91DA"
                }
            },
            info: {
                v: <CSSProperties> {
                    display: "flex", 
                    justifyContent: "flex-start", 
                    flexDirection: "column",
                    flex: 1
                },
                course: {
                    v: <CSSProperties> {
                        display: "flex", 
                        flexWrap: "wrap",
                        marginRight: "15px"
                    }
                },
                card: {
                    v: <CSSProperties> {
                        display: "inline-flex", 
                        alignItems: "center",
                        marginBottom: "0px",
                        paddingRight: "20px"
                    },
                    span: {
                        v: <CSSProperties> {
                            display: "inline-block",
                            position: "relative",
                            color: "#8F91DA",
                            padding: "0px 5px 0 0" 
                        }
                    }
                }
            },
            select: {
                v: <CSSProperties> {
                    maxWidth: "300px",
                    marginBottom: "20px"
                },
                title: {
                    v: <CSSProperties> {
                        textAlign: "center",
                        display: "inline-block",
                        position: "relative",
                        color: "#8F91DA",
                        padding: "0px 15px"
                    }
                },
                component: {
                    v: <CSSProperties> {
                        minWidth: '150px'
                    }
                }
            }
        },
        body: {
            addNote: {
                v: <CSSProperties> {
                    cursor: "pointer",
                    fontSize: "12px",
                    background: "none",
                    textDecoration: "underline",
                    marginTop: "-5px",
                },
                span: {
                    v: <CSSProperties> {
                        fontSize: "10px"
                    }
                }
            },
            noActive: {
                v: <CSSProperties> {
                    cursor: "default",
                    borderColor: "#00000000"
                },
                icon: {
                    v: <CSSProperties> {
                        color: "#C7C7C7"                        
                    }
                }
            }
        },
        footer: {
            FileUpload: {
                content: {
                    v: <CSSProperties> {
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column-reverse",
                        marginRight: "5px"
                    },
                },
                button: {
                    v: <CSSProperties> {
                        height: "40px",
                        minWidth: "fit-content",
                        display: "flex",
                        justifyContent: "center",
                        marginRight: "15px",
                    }
                },
                list: {
                    v: <CSSProperties> {
                        marginBottom: 0,
                    }
                }
            },
            save: {
                v: <CSSProperties> {
                    display: "flex",
                    alignItems: "center",
                    height: "40px"
                }
            },
            img: {
                v: <CSSProperties> {
                    display: "table-row",
                    height: "40px"
                }
            },
            extraStudent: {
                v: <CSSProperties> {
                    height: "40px",
                    padding: "0 10px",
                    borderRadius: "5px",
                    color: "#212529",
                    display: "flex",
                    alignItems: "center"
                },
                disabled: {
                    v: <CSSProperties> {
                        height: "40px",
                        padding: "0 10px",
                        borderRadius: "5px",
                        color: "#212529",
                        cursor: "default",
                        opacity: "0.6"
                    }
                }
            }
        }
    }
}