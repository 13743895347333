import React, { CSSProperties, useEffect, useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Constants from '../../constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { disableIdleLockSwitch, enableIdleLockSwitch, RootState } from '../../globals';
import { AllowedFileType } from './interfaces';
import heic2any from 'heic2any';

export interface FileUploadProps extends React.HTMLAttributes<HTMLDivElement> {
    allowedFiletypes: AllowedFileType[];
    onFilesChange: any,
    contentStyle?: CSSProperties,
    buttonStyle?: CSSProperties,
    buttonClass?: string,
    listStyle?: CSSProperties
    // onChange: (discount: number) => void;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({ allowedFiletypes, onFilesChange, contentStyle, buttonStyle, buttonClass, listStyle }) => {
    //init dispatch
    const dispatch = useDispatch();

    const idleLocked : boolean = useSelector((state : RootState) => state.idleLockSwitch.value);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const allowedTypesString = allowedFiletypes.map((ft: AllowedFileType) => ft.type).join(', ');
    const tooltip = `Solo se permiten archivos del tipo: ${allowedTypesString}`;

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            const validFiles: File[] = [];
            const maxFileSizeMB = 25 * 1024 * 1024; // 25MB en bytes
            let invalidFileAlert = '';

            for (let file of filesArray){
                let hasHeic = allowedFiletypes.filter((ft) => ft.type.toLocaleLowerCase() ==='heic' )[0];
                if ((file.type === 'image/heic' || file.name.toLocaleLowerCase().endsWith('.heic')) && hasHeic) {
                    let fileToHeic= await convertHeicToJpg(file);
                    let fileReduceSize = await reduceImageSize(fileToHeic);
                    file = fileReduceSize;
                }
                const isFileTypeAllowed = allowedFiletypes.some((ft: AllowedFileType) => ft.file === file.type);
                if (isFileTypeAllowed && file.size <= maxFileSizeMB) {
                    validFiles.push(file);
                } else {
                    invalidFileAlert += `El archivo ${file.name} no es válido. Asegúrate de que sea del tipo correcto (${allowedTypesString}) y no exceda los 25 MB.\n`;
                }
            }

            if (invalidFileAlert) {
                alert(invalidFileAlert);
            }

            if (validFiles.length > 0) {
                setSelectedFiles([...selectedFiles, ...validFiles]);
            }
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        document.getElementById('fileInput')?.click();
    };

    const handleRemoveFile = (index: number) => {
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    }

    const convertHeicToJpg = async (file: File): Promise<File> => {
        let jpgFile;
        try {
            dispatch(enableIdleLockSwitch());
            const convertedBlob = await heic2any({ blob: file });
            const blobToUse = Array.isArray(convertedBlob) ? convertedBlob[0] : convertedBlob;
            jpgFile = new File([blobToUse], file.name.replace(/\.heic$/i, '.jpg'), {
                type: 'image/jpeg',
            });
            console.log('Imagen HEIC convertida a JPG:', file);
        } catch (error) {
            console.error('Error al convertir la imagen:', error);
        } finally {
            dispatch(disableIdleLockSwitch());
        }
        return jpgFile? jpgFile : file;
    };

    function reduceImageSize(file: File, quality: number = 0.7, maxSize: number = 800): Promise<File> {
        return new Promise((resolve, reject) => {
            try {
                dispatch(enableIdleLockSwitch());
                const reader = new FileReader();
    
                reader.onload = (e) => {
                    const img = new Image();
                    img.onload = () => {
                        const ratio = Math.min(maxSize / img.width, maxSize / img.height, 1);
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        if (!ctx) throw new Error('Error al obtener contexto del canvas');
                        
                        canvas.width = img.width * ratio;
                        canvas.height = img.height * ratio;
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    
                        const mimeType = file.type || 'image/jpeg';// Usar el tipo MIME del archivo original (si está disponible)
                        canvas.toBlob((blob) => {
                            if (blob) {
                                const compressedFile = new File([blob], file.name, { type: mimeType });
                                resolve(compressedFile);
                            } else {
                                throw new Error('Error al crear el archivo comprimido');
                            }
                        }, mimeType, quality);
                    };
                    img.onerror = () => { throw new Error('Error al cargar la imagen'); };
                    img.src = e.target?.result as string;
                };
                
                reader.onerror = () => { throw new Error('Error al leer el archivo'); };
                reader.readAsDataURL(file);
            } catch (error) {
                reject(error);
            } finally {
                dispatch(disableIdleLockSwitch())
            }
        });
    }    

    useEffect(() => {
        onFilesChange([...selectedFiles]);
    }, [selectedFiles]);

    return (
        <div style={{...contentStyle}}>
            <OverlayTrigger overlay={
                <Tooltip>
                    {tooltip}
                </Tooltip>
            }>
                <div
                    style={{
                        ...buttonStyle,
                        width: "60%",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        border: "2px dotted #000000",
                    }}
                    className={buttonClass ? buttonClass : ""}>
                    <input
                        id="fileInput"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        style={{
                            display: "none"
                        }}
                    />
                    <button
                        disabled={idleLocked}
                        type="button"
                        className="btn btn-default d-flex align-items-center"
                        onClick={handleClick}
                        // style={{
                        //     background: "#F1F1FF",
                        //     border: "2px dashed #8F91DA",
                        //     width: "100%",
                        //     color: "#8F91DA"
                        // }}
                    >
                        <FontAwesomeIcon icon={solid("arrow-up-from-bracket")} />
                        <span className='d-none d-lg-flex ps-2'>{Constants.Screens.Finances.modalPayments.uploadFile}</span>
                    </button>
                </div>
            </OverlayTrigger>
            {selectedFiles.length > 0 && (
                <div>
                    <ul style={{
                        ...listStyle,
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        padding: "0"
                    }}>
                        {selectedFiles.map((file, index) => (
                            <li key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    padding: "0",
                                    columnGap: "10px"
                                }}>
                                {file.name}
                                <button
                                    type="button"
                                    onClick={() => handleRemoveFile(index)}
                                    style={{
                                        fontSize: "20px",
                                        backgroundColor: "transparent"
                                    }}>
                                    <FontAwesomeIcon icon={solid("times")} />
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )
            }
        </div >
    );
};

export default FileUploadComponent;
